.loading-page {
  background: rgba(0, 0, 0, 0.1);
  z-index: 100;
  height: 100vh;
  /*position: absolute;*/
  /*top: 0px;*/
}
.homepage .loading-icon,
.content .loading-icon {
  height: 200px;
  position: fixed;
  left: calc(50vw);
  top: calc(50vh - 100px);
  z-index: 200;
}
.loading-icon svg {
  position: absolute;
  z-index: 200 !important;
}

.layer-one {
  font-size: 100px;
  animation: rotate-one 0.8s infinite linear;
  color: #ff9ad7;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
}
.layer-two {
  font-size: 90px;
  animation: rotate-two 1s infinite linear;
  color: #5f5cff;
  left: calc(50% - 45px);
  top: calc(50% - 45px);
}
.layer-three {
  font-size: 80px;
  animation: rotate-three 1.2s infinite linear;
  color: #ffe352;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
}
.layer-four {
  font-size: 70px;
  animation: rotate-four 1.4s infinite linear;
  color: #f87211;
  left: calc(50% - 35px);
  top: calc(50% - 35px);
}
.layer-five {
  font-size: 60px;
  animation: rotate-five 1.6s infinite linear;
  color: #9ddcff;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
}
@keyframes rotate-one {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate-two {
  0% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(210deg);
  }
  100% {
    transform: rotate(390deg);
  }
}
@keyframes rotate-three {
  0% {
    transform: rotate(60deg);
  }
  50% {
    transform: rotate(240deg);
  }
  100% {
    transform: rotate(420deg);
  }
}
@keyframes rotate-four {
  0% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(450deg);
  }
}

@keyframes rotate-five {
  0% {
    transform: rotate(120deg);
  }
  50% {
    transform: rotate(300deg);
  }
  100% {
    transform: rotate(480deg);
  }
}
.circle__box {
  width: 200px;
  height: 200px;
  margin: 50px auto;
  position: relative;
}

.circle__wrapper {
  width: 100px;
  height: 200px;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.circle__wrapper--right {
  right: 0;
}

.circle__wrapper--left {
  left: 0;
}

.circle__whole {
  width: 160px;
  height: 160px;
  border: 20px solid transparent;
  border-radius: 50%;
  position: absolute;
  top: 0;
  transform: rotate(-135deg);
  box-sizing: content-box;
}

.circle__right {
  border-top: 20px solid teal;
  border-right: 20px solid teal;
  right: 1px;
  animation: circleRight 1s infinite ease-in;
}

.circle__left {
  border-bottom: 20px solid teal;
  border-left: 20px solid teal;
  left: 1px;
  animation: circleLeft 1s infinite ease-out;
}

@keyframes circleRight {
  0% {
    transform: rotate(-135deg);
  }
  50%,
  100% {
    transform: rotate(45deg);
  }
}

@keyframes circleLeft {
  0%,
  50% {
    transform: rotate(-135deg);
  }
  60%,
  100% {
    -webkit-transform: rotate(45deg);
  }
}
