@import "./index.scss";
.entity-form-header {
  margin-bottom: 28px;
  width: 100%;
  position: relative;
  h2 {
    @extend .title-16;
    box-sizing: border-box;
    width: 100%;
    margin: 0px;
    padding: 0px 24px;
  }
  .close-page {
    @extend .flex-by-row-between;
    height: 24px !important;
    width: 24px !important;
    margin: 0px !important;
  }
}

.entity-form {
  width: 100%;
  .form-section {
    width: 100%;
    .entity-field {
      @extend .text-12-cap;
      width: 100%;
      margin-bottom: 16px;
      position: relative;

      .entity-field-label {
        @extend .flex-by-row-between;
        margin-bottom: 4px;
      }

      .entity-field-content {
        position: relative;
        input:not([type="checkbox"]) {
          @extend .text-box;
          width: 100%;
        }

        .select-handle {
          @extend .flex-by-row-between;
          @extend .text-box;
          width: 100%;
          svg {
            margin-right: 8px;
            opacity: 0.24;
          }
        }
      }
    }
  }
  .button-wrapper {
    @extend .flex-by-row-centered;
    width: 100%;
    margin: 48px 0px 32px 0px;
    button,
    a {
      @extend .text-14-upper;
      width: 150px;
      height: 40px;
    }
    button[type="submit"] {
      @extend .button-purple;
      @extend .text-14-upper;
    }
    .delete-button {
      color: #fe4945;
    }
  }
}

@media only screen and (min-width: $mobile-width) {
  .entity-form-header {
    margin-bottom: 16px;
    h2 {
      text-align: left;
      padding: 0px;
    }
  }
  .entity-form {
    .form-body {
      display: flex;
      flex-direction: row;
      .form-section {
        padding: 0px 16px;
        border-left: 2px solid var(--main-purple);
        position: relative;
      }
    }
    .button-wrapper {
      width: 100%;
      margin-bottom: 0px;
      bottom: 16px;
    }
  }
}
