.popup-container {
  height: auto;
  width: 100%;
  position: absolute;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  top: 0px;
}
.popup-overlay {
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,0.7);
    opacity: 0.8;
    position: fixed;
    left: 0px;
    top: 0px;
}
