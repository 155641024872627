@import "./index.scss";
.close-page {
  @extend .flex-by-row-centered;
  @extend .text-20;
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 32px;
  padding: 0px;
  z-index: 99;
  svg {
    margin: 0px;
  }
}
