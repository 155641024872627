@import "./index.scss";
.data-category {
  @extend .flex-by-column-centered;
  width: 100%;
  padding-top: 8px;
  .category-title {
    @extend .flex-by-row-left;
    @extend .title-10;
    opacity: 0.56;
    width: 100%;
    padding: 0px;
    margin-top: 16px;
    .filler {
      position: relative;
      top: 1px;
      width: 1px;
      height: 1px;
      flex-grow: 1;
      margin-left: 16px;
      background: var(--eerie-black);
    }
  }
}
.data-category:first-child {
  padding-top: 0px;
}

@media only screen and (min-width: $mobile-width) {
  .data-category {
    margin-bottom: 0px;
    flex-direction: row;
    align-items: stretch;
    align-content: stretch;
    flex-wrap: wrap;
    .category-title {
      padding: 0px 12px;
    }
    .category-title:first-child {
      margin-top: 0px;
    }
  }
}
