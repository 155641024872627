@import "../../../index.scss";
.user-anonymous-section.hero-page {
  @extend .flex-by-column-top;
  padding: 0px 0px 100vw 0px;
  position: relative;
  .hero-content {
    @extend .flex-by-column-centered;
    width: 255px;
    .hero-title {
      font-size: 34px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.18x;
      color: var(--eerie_black);
      margin: 24px 0px;
      text-align: center;
    }
  }
  .hero-image {
    background-image: url("../../../images/hero.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom right;
    width: 100vw;
    height: 100vw;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
}

@media only screen and (min-width: $mobile-width) {
  .user-anonymous-section.hero-page {
    height: calc(100vh - #{$navbar-height-desktop});
    padding: 0px 10%;
    z-index: 2;
    flex-direction: row;
    .hero-content {
      position: relative;
      top: -80px;
      width: auto;
      max-width: 35%;
      align-items: flex-start;
      box-sizing: content-box;
      z-index: 1;
      .hero-title {
        font-size: 40px;
        margin: 0px 0px 24px 0px;
        letter-spacing: 0.1px;
        text-align: left;
      }
    }
    .hero-image {
      position: absolute;
      top: 0px;
      background-position: right top;
      width: 100vw;
      height: 100vh;
      background-size: contain;
      background-position: top right;
    }
  }
}
