@import "./index.scss";
.step-content-card.schedules {
  .eater-name {
    font-size: 14px;
    height: 24px;
    margin-bottom: 8px;
    text-transform: capitalize;
  }
  .form-field {
    margin-bottom: 22px;
    .notes {
      @extend .text-10-upper;
      opacity: 0.56;
      margin: 4px 0px 8px 0px;
    }
    .week {
      @extend .flex-by-row-between;
      .meal {
        @extend .flex-by-row-centered;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid var(--eerie-grey);
        color: var(--eerie-grey);
        text-align: center;
        margin: 0px;
      }

      .meal.selected {
        background-color: var(--main-purple);
        border: 1px solid var(--main-purple);
        color: white;
      }
    }
  }
}

@media only screen and (max-width: $mobile-small-width) {
  .step-content-card.schedules {
    .form-field {
      .week {
        .meal {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}
