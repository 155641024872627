@import "./index.scss";
.user-anonymous-section.how-it-works {
  .section-body {
    .info-card {
      position: relative;
      margin-bottom: 80px;
      font-weight: normal;
      letter-spacing: 0.44px;
      color: var(--eerie-grey-80);
      .how-it-works-step-icon {
        width: 80px;
        height: 80px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: -40px;
        left: 50px;
      }
      .how-it-works-step-text {
        padding-top: 48px;
      }
    }
    .info-card:nth-child(1) {
      margin-top: 24px;
      .how-it-works-step-icon {
        background-image: url("../../../images/icon_note.svg");
      }
    }
    .info-card:nth-child(2) {
      .how-it-works-step-icon {
        background-image: url("../../../images/icon_calendar.svg");
        top: -55px;
        left: 45px;
      }
      margin-bottom: 75px;
    }
    .info-card:nth-child(3) {
      margin-bottom: 0px;
      .how-it-works-step-icon {
        background-image: url("../../../images/icon_cart.svg");
        left: 25px;
        top: -45px;
      }
    }
  }
}

@media only screen and (min-width: $mobile-width) {
  .user-anonymous-section.how-it-works {
    position: relative;
    z-index: 1;
    h3 {
      text-align: left;
      width: 100%;
    }
    .section-body {
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      .info-card {
        max-width: 358px;
      }
      .info-card:nth-child(1) {
        margin: 50px 10px 0px 0px;
      }
      .info-card:nth-child(2) {
        margin: 50px 10px 0px 10px;
      }
      .info-card:nth-child(3) {
        margin: 50px 0px 0px 10px;
      }
    }
  }
}
