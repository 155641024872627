@import "./index.scss";
.error-message {
  @extend .flex-by-row-left;
  @extend .text-14;
  width: 100%;
  height: 24px;
  border-radius: 16px;
  margin-bottom: 4px;
  svg {
    margin-right: 8px;
  }
}
.error-message.has-error {
  color: var(--warning-red);
  svg {
    color: var(--warning-red) !important;
  }
}
.error-message.no-error {
  svg {
    display: none;
  }
}
