@import "../../index.scss";
.sticker {
  @extend .card-16;
  @extend .flex-by-row-between;
  margin: 4px 0px;
  padding-left: 56px;
  height: 56px;
  position: relative;
  .sticker-icon {
    @extend .flex-by-row-centered;
    svg {
      font-size: 20px;
    }
    margin-right: 8px;
    position: absolute;
    left: 8px;
    width: 40px;
    height: 40px;
  }
  .sticker-content {
    @extend .overflow-ellipsis;
    @extend .title-14;
    max-width: 100%;
    padding-right: 8px;
  }
  .sticker-action {
    @extend .flex-by-row-centered;
    width: 48px;
    padding-right: 8px;
    position: absolute;
    right: 0px;
  }
}
.sticker.with-action {
  padding-right: 56px;
}
.sticker.auto-width {
  width: auto;
}
@media only screen and (min-width: $mobile-width) {
  .sticker {
    box-shadow: 0 4px 12px 0 rgba(28, 29, 33, 0.12);
    width: 280px;
    margin: 4px 0px;
    padding: 8px 8px 8px 56px;
    .sticker-icon {
      left: 8px;
    }
  }
}
