@import "../../index.scss";
.homepage.login {
  @extend .flex-by-column-centered;
  background-color: white;
  h2 {
    @extend .title-20;
    margin: 0px 0px 24px 0px;
  }
  input {
    @extend .text-16;
  }
}
