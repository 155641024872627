@import "../../../index.scss";
.nothing {
  @extend .flex-by-column-centered;
  @extend .card-16;
  padding: 16px;
  height: 160px;
  width: 100%;
  span {
    @extend .text-14;
    text-align: center;
    color: var(--eerie-grey-56);
  }
  .nothing-icon {
    @extend .flex-by-row-centered;
    width: 56px;
    height: 56px;
    border: 6px solid var(--ghost-white);
    background: var(--background-grey-75);
    color: var(--main-purple);
    border-radius: 50%;
    margin-bottom: 8px;
    svg {
      font-size: 20px;
    }
  }
}
