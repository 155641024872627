@import "../../index.scss";
.footer {
  @extend .flex-by-row-between;
  position: absolute;
  width: 100%;
  bottom: 0px;
  padding: 20px $medium-margin;
  .footer-field {
    @extend .text-12-cap;
    color: var(--eerie-grey-56);
    padding: 0px;
    svg {
      margin-right: 5px;
    }
  }
  .footer-field:first-child {
    margin-left: 0px;
  }
}
//desktop
@media only screen and (min-width: $mobile-width) {
  .footer {
    padding-left: $main-margin;
    justify-content: flex-start;
    .footer-field {
      margin-left: 30px;
    }
  }
}
