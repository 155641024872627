@import "../../../index.scss";

$header-height: 100px;
.dish-list-header,
.dish-list-footer {
  @extend .card;
  @extend .title-16;
  @extend .flex-by-row-centered;
  position: fixed;
  width: 100%;
  height: $header-height;
  z-index: 2;
}
.dish-list-header {
  top: 0px;
  .close-page {
    margin: 40px;
  }
}

.dish-list-footer {
  bottom: 0px;
  button {
    @extend .button-purple;
    width: 160px;
    height: 40px;
    margin: 0px 8px;
  }
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  button.reset {
    @extend .button-transparent;
    color: var(--warning_red);
    svg {
      font-size: 20px;
      margin-right: 5px;
    }
  }
}
.dish-list {
  @extend .flex-by-column-centered;
  width: 100%;
  padding: calc(#{$header-height} + 16px) 16px;
  flex-wrap: wrap;
  z-index: 1;
  background-color: white;
  .restaurant-entry {
    @extend .flex-by-column-centered;
    width: 100%;
    align-items: flex-start;
    font-size: 12px;
    margin: 8px 0px;
    .restaurant-title {
      @extend .flex-by-row-centered;
      @extend .title-10;
      opacity: 0.56;
      width: 100%;
      height: 16px;
      padding: 0px 8px;
      margin-bottom: 8px;
      span {
        @extend .overflow-ellipsis;
        max-width: 100%;
      }
      .filler {
        width: 1px;
        height: 1px;
        flex-grow: 1;
        margin-left: 8px;
        background-color: var(--eerie-grey-56);
      }
    }
    .restaurant-dish-list {
      @extend .flex-by-row-left;
      flex-wrap: wrap;
      position: relative;
      width: 100%;
      .meal-select {
        padding: 0px;
        margin: 8px 0px;
        opacity: 0.56;
        width: 100%;
        .sticker {
          height: auto;
          width: 100%;
          box-shadow: none;
          background-color: transparent;
          .sticker-icon {
            border-radius: 50%;
            background-color: var(--eerie-grey-10);
          }
          .sticker-content {
            .dish-name {
              white-space: pre-wrap;
            }
          }
        }
      }
      .meal-select.selected-dish {
        opacity: 1;
        .sticker {
          .sticker-icon {
            background-color: var(--background-light-purple);
            color: var(--main-purple);
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $mobile-width) {
  .dish-list {
    .restaurant-entry {
      padding: 8px 42px;
      .restaurant-dish-list {
        .meal-select {
          width: 50%;
        }
      }
    }
  }
}
