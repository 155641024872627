@import "./index.scss";
.step-form.favorites {
  .add-restaurant {
    @extend .flex-by-row-left;
    position: relative;
    padding: 16px 0px;
    width: 100%;
    .search-icon {
      @extend .flex-by-row-centered;
      color: var(--eerie-grey-25);
      position: absolute;
      width: 40px;
      left: 0px;
      z-index: 1;
    }
    .geosuggest {
      width: 100%;
      flex-grow: 1;
      input {
        @extend .card-textarea;
        @extend .text-16;
        padding-left: 40px;
      }
    }
  }

  .existing-restaurant {
    @extend .card-16;
    @extend .flex-by-column-centered;
    position: relative;
    width: 100%;
    margin-bottom: 16px;
    padding: 32px 16px 16px 16px;
    .restaurant-info {
      @extend .flex-by-column-left;
      position: relative;
      width: 100%;
      padding-left: 32px;
      .restaurant-icon {
        @extend .flex-by-row-centered;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 32px;
        height: 32px;
        font-size: 18px;
        color: var(--main-purple);
      }
      .restaurant-name {
        @extend .text-16;
      }
      .restaurant-address {
        @extend .text-14;
        letter-spacing: 1px;
        @extend .overflow-ellipsis;
        width: 100%;
        opacity: 0.5;
      }
    }
    .restaurant-comments {
      @extend .card-textarea;
      @extend .text-16;
      width: 100%;
      min-height: 60px;
      margin-top: 16px;
      padding: 8px;
      text-align: justify;
    }

    .delete-restaurant {
      @extend .flex-by-row-centered;
      width: 20px;
      position: absolute;
      right: 16px;
      top: 16px;
      padding: 0px;
      svg {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (min-width: $mobile-width) {
}
