@import "./index.scss";
.user-anonymous-section.cta {
  .section-body {
    background-image: url("../../../images/service-details-img.svg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 60%;
    padding-top: 65%;
  }
}
@media only screen and (min-width: $mobile-width) {
  .user-anonymous-section.cta {
    .section-body {
      background-position: 10% 40%;
      background-size: 30%;
      padding-top: 0px;
      padding-left: 40%;
      height: 360px;
    }
  }
}
