@import "../../index.scss";
.button-call-to-action {
  @extend .flex-by-row-centered;
  @extend .text-14-button;
  color: var(--button-grey);
  border-radius: 8px;
  height: 32px;
  padding: 0px 18px !important;
  border: solid 1px rgba(94, 97, 110, 0.16);
  box-sizing: border-box;
  svg {
    font-size: 18px;
    margin-right: 18px;
  }
}
// mobile first
@media only screen and (min-width: $mobile-width) {
  .button-call-to-action {
    height: 40px;
  }
}
