@import "../../../index.scss";
.instance-delivered {
  @extend .text-14-cap;
  width: calc(100% - 32px);
  margin: 0px 16px;
  padding: 16px 0px;
  justify-content: flex-start;
  border-bottom: 1px dotted var(--eerie-grey-10);
  box-sizing: border-box;
  .instance-info {
    overflow-y: hidden;
    .instance-info-row {
      @extend .flex-by-row-left;
      line-height: 20px;
    }
    .instance-info-row:first-child {
      margin-bottom: 8px;
    }
  }
  .instance-feedback {
    .instance-info-row {
      line-height: 20px;
    }

    .instance-info-row.comment-info {
      @extend .flex-by-column-left;
      background-color: white;
      margin-top: 8px;
      .info-label {
        @extend .text-12;
        font-weight: normal;
        margin-bottom: 4px;
      }
    }
  }
  .instance-info-row .info-label {
    font-weight: 700;
    display: inline-block;
  }
  .instance-info-row .info-text {
    font-weight: 200;
    display: inline-block;
  }
}
.instance-delivered:last-child {
  border-bottom: none;
}
