@import "./index.scss";
.user-anonymous-section.service-details {
  position: relative;
  padding-bottom: 200px;
  .section-body {
    .info-card {
      @extend .card-8;
      @extend .flex-by-column-left;
      @extend .text-16;
      padding: 16px 24px;
      border-left: 24px solid;
      .service-details-meal-type {
        text-transform: capitalize;
        color: var(--landing-grey);
      }
      .service-details-delivery-window {
        @extend .flex-by-row-between;
        @extend .text-10;
        margin: 14px 0px;
        padding-right: 20%;
        width: 100%;
        max-width: 360px;
        @extend .flex-by-row-upper-left;
        .hour-range {
          @extend .title-20;
          font-weight: normal;
          margin: 0px 5px;
        }
        .service-details-delivery-window-hour {
          @extend .title-20;
          color: var(--landing-grey);
          position: relative;
        }
      }
      .service-details-cancel-time {
        @extend .text-12;
        color: var(--button-grey);
      }
    }
    .info-card:nth-child(1) {
      border-left-color: var(--landing-orange);
    }
    .info-card:nth-child(2) {
      border-left-color: var(--landing-green);
    }
  }
  .phone-form {
    position: absolute;
    bottom: 24px;
  }
}
@media only screen and (max-width: $mobile-small-width) {
  .user-anonymous-section.service-details {
    .section-body {
      .info-card {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
}
@media only screen and (min-width: $mobile-width) {
  .user-anonymous-section.service-details {
    align-items: center;
    padding-bottom: 40px;
    position: relative;
    height: 450px;
    .section-body {
      flex-direction: column;
      align-items: flex-end;
      margin: 0px;
      min-width: 350px;
      width: 35%;
      position: absolute;
      left: 10%;
      top: 80px;
      .info-card {
        width: 100%;
      }
    }
    h3 {
      position: absolute;
      left: calc(50% + 80px);
      top: 80px;
    }
    .phone-form {
      left: calc(50% + 80px);
      top: 0px;
    }
  }
}
