@import "./index.scss";
button.invoice-action {
  background-color: var(--main-purple);
  box-shadow: 0 8px 32px 0 rgba(95, 92, 255, 0.24);
  color: white;
  width: 160px;
  height: 42px;
  border-radius: 8px;
  text-transform: capitalize;
}
button.invoice-action[disabled] {
  opacity: 0.56;
}
.total-due-header {
  @extend .flex-by-row-between;
  @extend .text-14;
  width: 100%;
  max-width: 400px;
  .filler {
    border-bottom: 1px dotted rgba(28, 29, 33, 0.24);
    height: 14px;
    flex-grow: 1;
    margin: 0px 5px;
  }
  .sum {
    font-weight: bold;
  }
}

.total-due-form {
  @extend .flex-by-column-centered;
  @extend .text-14;
  @extend .card-16;
  box-shadow: 0px 4px 32px 8px var(--eerie-grey-25);
  width: 100%;
  padding: 24px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  z-index: 1;

  .form-payment {
    .toggle-show-card {
      @extend .flex-by-row-between;
      @extend .title-14;
      width: 100%;
      height: 45px;
      padding: $medium-margin;
      background: var(--background-grey-100);
      svg {
        font-size: 16px;
      }
    }
  }
}
.total-due-form.without-card {
  .form-payment {
    box-shadow: none;
  }
}
.pay-by-card {
  @extend .flex-by-column-top;
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding: 0px 32px;
  top: 0px;
  left: 0px;
  background-color: white;
  z-index: 2;

  .page-title {
    @extend .title-20;
    @extend .flex-by-row-centered;
    width: 100%;
    height: $navbar-height-mobile;
  }
  .card-form {
    margin-top: 16px;
    max-width: 400px;
  }
  .checkbox {
    margin-top: 8px;
    width: 100%;
    max-width: 400px;
  }
  .invoice-action {
    margin-top: 32px;
  }
}
@media only screen and (max-width: $mobile-small-width) {
  .pay-by-card {
    padding: 0px 8vw;
  }
}
//750px or above
@media only screen and (min-width: $mobile-width) {
  .total-due-form {
    justify-content: space-between;
    border-radius: 16px;
    box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.12);
    width: 375px;

    top: calc(#{$navbar-height-desktop} + 16px);
    right: 64px;
    left: auto;
  }
  .total-due-form.with-card {
    height: 300px;
  }
  .total-due-form.without-card {
    height: 200px;
  }
  .pay-by-card {
    .page-title {
      height: $navbar-height-desktop;
    }
  }
}
