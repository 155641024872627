.back-page{
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 0!important;
  padding: 0px!important;
  width: 50px!important;
  height: 96px!important;
  line-height: 96px!important;
  text-align: center!important;
  border: none!important;
  font-size: 20px!important;
  font-weight: 200!important;
  z-index: 99 !important;
}