@import "../../../index.scss";
.calendar-day {
  @extend .flex-by-column-top;
  @extend .text-16;
  height: 40px;

  .calendar-date {
    height: 28px;
    width: 28px;
    text-align: center;
    border-radius: 50%;
  }
}

.calendar-day.past {
  color: var(--background-grey-100);
}

.calendar-day.today {
  color: var(--main-purple);
  font-weight: bold;
}

.calendar-day.selected-day .calendar-date {
  background: var(--main-purple);
  color: white;
  font-weight: 500;
}

// to delete

@media only screen and (max-width: 800px) {
  .instances-wrapper {
    /*flex-direction: column;*/
    font-size: 7px;
  }
  .instances-wrapper .number-of-meals {
    display: none;
  }
}
