@import "../../index.scss";
.bubble {
  @extend .card-16;
  @extend .flex-by-column-centered;
  padding: 12px 16px;
  position: absolute;
  z-index: 10;
}
.dropdown-menu-toggle {
  position: absolute;
}
.bubble.dropdown-menu {
  min-width: 200px;
  padding: 24px 16px;
  top: 35px;
  right: 35px;
  li {
    width: 100%;
    margin-bottom: 16px;
    button,
    span {
      @extend .flex-by-row-between;
      @extend .text-14-cap;
      width: 100%;
      padding: 0px;
      svg {
        font-size: 16px;
      }
    }
  }
  li:last-child {
    margin-bottom: 0px;
  }
}

.bubble::after {
  content: "";
  background-color: white;
  border: none;
  position: absolute;
  top: -8px;
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
}
.bubble.small-arrow::after {
  content: "";
  position: absolute;
  top: -4px;
  left: 50%;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
}

.bubble.upper-right:after {
  right: 20px;
}

.bubble.upper-middle:after {
  right: calc(50% - 10px);
}

.bubble-overlay {
  @extend .overlay;
  z-index: 2;
}
