@import "../../index.scss";
.time-label {
  @extend .flex-by-row-centered;
  @extend .text-12-cap;
  height: 32px;
  border-radius: 24px;
  background-color: var(--background-grey-50);
  padding: 0px 16px;
  font-weight: bold;
  white-space: nowrap;
  input {
    height: 16px;
    width: 32px !important;
    border: none;
    padding: 0px;
    background-color: transparent;
    font-weight: bold;
  }
  input,
  svg {
    margin: 0px 6px;
  }
}

.time-label.done {
  background-color: var(--background-green-75);
  color: var(--dark-green);
}

button.time-label {
  @extend .text-14-button;
  @extend .button-purple;
}

.time-text {
  @extend .flex-by-row-centered;
  @extend .text-10-upper;
  white-space: pre;
  height: 16px;
  input {
    @extend .text-10-upper;
    font-weight: bold;
    height: 16px;
    width: 38px !important;
    border: none;
    padding: 0px 2px;
    margin: 0px 2px 0px 6px;
    background-color: transparent;
  }
}
@media only screen and (min-width: $mobile-width) {
  div.time-label {
    display: flex;
  }
}
