@import "./index.scss";
.dashboard-content {
  @extend .flex-by-column-centered;
  width: 100%;
  padding: 242px 16px 32px 16px;
  .add-button {
    @extend .flex-by-column-centered;
    border-radius: 50%;
    position: fixed;
    right: 20px;
    bottom: 40px;
    z-index: 99;
    background-color: white;
    box-shadow: 0 8px 32px 0 rgba(95, 92, 255, 0.24);
    svg {
      font-size: 40px;
      color: #5f5cff;
      margin: 10px;
    }
  }
}

@media only screen and (min-width: $mobile-width) {
  .dashboard-content {
    padding: 32px 72px 32px 212px;
  }
}
