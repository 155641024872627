@import "./index.scss";
$header-padding: 40px;
$step-circle-width: 32px;

.show-shadow {
  box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.12);
}

.page-header {
  @extend .flex-by-column-centered;
  background-color: white;
  z-index: 1;
  position: fixed;
  width: 100vw;
  height: $navbar-height-mobile;
  padding: 0px;
  top: 0px;
  left: 0px;
  .page-title {
    @extend .flex-by-row-centered;
    @extend .title-20;
    width: 100%;
    height: $navbar-height-mobile;
    span {
      @extend .title-16;
    }
  }
  .page-sub-header {
    @extend .flex-by-row-between;
    width: 100%;
    max-width: $max-form-width;
    height: 30px;
    position: relative;
    padding: 0px 16px;
    .profile-tab {
      @extend .flex-by-row-centered;
      @extend .title-10;
      padding: 0px 0px 12px 0px;
      border-bottom: 2px solid transparent;
      color: var(--eerie-grey-25);
      height: 30px;
    }
    .profile-tab.editting {
      color: var(--main-purple);
      border-bottom: 2px solid var(--main-purple);
    }
    .filler {
      position: relative;
      background-color: var(--eerie-grey-25);
      top: -11px;
      height: 1px;
      flex-grow: 1;
    }
    .filler.done {
      background-color: var(--main-purple);
    }
    .step-wrapper {
      @extend .flex-by-column-centered;
      width: 32px;
      height: auto;
      position: relative;
      .step-dot {
        width: $step-circle-width;
        height: $step-circle-width;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        svg {
          margin: 0px;
        }
      }
      .step-dot.done {
        background: var(--main-purple);
        border: 1px solid var(--main-purple);
        color: white;
      }
      .step-dot.disabled {
        pointer-events: none;
      }
      .step-dot.todo {
        background-color: white;
        border: 1px solid var(--eerie-grey-25);
        color: var(--eerie-grey-25);
      }
      .step-dot.editting {
        background: white;
        border: 1px solid var(--main-purple);
        color: var(--main-purple);
      }
      .step-dot.todo + .step-text {
        color: var(--eerie-grey-25);
      }
      .step-dot.editting + .step-text {
        color: rgba(28, 29, 33, 1);
      }

      .step-text {
        @extend .title-10;
        margin-top: 6px;
      }
    }
  }
}
.profile {
  .page-header {
    height: calc(#{$navbar-height-mobile} + 30px);
  }
}
.form-footer {
  @extend .flex-by-row-centered;
  background-color: white;
  z-index: 1;
  position: fixed;
  width: 100vw;
  bottom: 0px;
  left: 0px;
  padding: 25px 16px;
  .form-footer-content {
    @extend .flex-by-row-between;
    width: 100%;
    max-width: $max-form-width;
  }
  .setup-nav {
    height: 32px;
    font-size: 14px;
    border-radius: 8px;
    padding: 0px 12px;
    span {
      line-height: 32px;
    }
  }

  .setup-nav.next {
    background-color: var(--main-purple);
    color: white;
    width: 100px;
    svg {
      margin-left: 12px;
      margin-right: 0px;
    }
  }

  .setup-nav.previous {
    color: var(--eerie_black);
    background-color: transparent;
    padding-left: 0px;
    svg {
      margin-right: 12px;
      margin-left: 0px;
    }
  }

  .setup-nav.previous:disabled {
    opacity: 0.25;
    cursor: text;
    pointer-events: all !important;
  }
}
@media only screen and (max-width: $mobile-small-width) {
  .page-header {
    .page-title {
      font-size: 16px;
    }
    .page-sub-header {
      padding: 0px 5%;
      .step-wrapper {
        .step-text {
          letter-spacing: 0.5px;
        }
      }
    }
  }
}
@media only screen and (min-width: $mobile-width) {
  .form-footer {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    .form-footer-content {
      padding: 0px;
    }
  }
  .page-header {
    margin: 0 auto;
    h2 {
      display: block;
    }
  }

  .homepage.profile {
    .page-header {
      .page-title {
        height: $navbar-height-desktop;
      }
      .page-sub-header {
        padding: 0px 8px;
      }
      height: calc(#{$navbar-height-desktop} + 30px);
    }
  }

  .form-footer.show-shadow {
    position: fixed;
    width: 100vw;
    left: 0px;
    .form-footer-content {
      max-width: 400px;
      padding: 0px 16px;
    }
  }
}
