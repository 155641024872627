@import "../../index.scss";
.tags {
  width: 100%;
  position: relative;
  .edit-tags {
    @extend .flex-by-row-centered;
    position: absolute;
    right: 0px;
    top: -20px;
    svg {
      font-size: 12px;
    }
  }
  .sticker {
    width: 100%;
    margin-bottom: 8px;
  }
  .tags-list-wrapper,
  .tags-list-title,
  .tags-list-footer {
    @extend .card-8;
    position: fixed;
    left: 5vw;
    width: 90vw;
  }
  .tags-list-wrapper,
  .tags-list-title {
    top: 60px;
  }

  .tags-list-wrapper {
    background-color: var(--ghost_white);
    overflow-y: scroll;
    height: calc(100vh - 120px);
    padding: 60px 0px 80px 0px;
    z-index: 1;
    .tags-list {
      @extend .flex-by-row-left;
      flex-wrap: wrap;
      padding: 10px 15px;
      margin: 0px;

      button {
        border-radius: 8px;
        padding: 8px 10px;
        margin: 5px;
        text-transform: capitalize;
      }
      button.selected {
        background-color: var(--main-purple);
        border: 1px solid var(--main-purple);
        color: white;
      }
      .add-entry {
        margin: 5px;
      }
    }

    .tags-list-title,
    .tags-list-footer {
      @extend .flex-by-row-centered;
      background-color: white;
    }
    .tags-list-title {
      @extend .title-16;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      height: 60px;
    }
    .tags-list-footer {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      bottom: 60px;
      height: 80px;
      button {
        @extend .button-purple;
        @extend .text-14;
        height: 40px;
        width: 140px;
      }
    }
  }
}
.tags.empty {
  margin-bottom: 32px;
}

@media only screen and (min-width: $mobile-width) {
  .tags {
    .tags-list-wrapper,
    .tags-list-title,
    .tags-list-footer {
      left: calc(50vw - 220px);
      width: 440px;
    }
    .tags-list-wrapper,
    .tags-list-title {
      top: 25vh;
    }
    .tags-list-wrapper {
      height: 50vh;
      .tags-list-footer {
        bottom: 25vh;
      }
    }
  }
}
