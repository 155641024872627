@import "./index.scss";

.geosuggest {
  margin: 0px;
  padding: 0px;
  position: relative;
  .geosuggest__suggests-wrapper {
    width: 100%;
    z-index: 1;
    position: absolute;
    left: 0px;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 4px 14px 14px 0px rgba(28, 29, 33, 0.12);
    ul.geosuggest__suggests.geosuggest__suggests--hidden {
      display: none;
    }
    ul {
      @extend .flex-by-column-centered;
      list-style-type: none;
      width: 100%;
      padding-inline-start: 0px;
      margin: 0px;
      li {
        @extend .overflow-ellipsis;
        width: 100%;
        padding: 0px 8px;
        box-sizing: border-box;
        span {
          @extend .text-12;
          height: 42px;
          line-height: 42px;
        }
      }
    }
  }
}

.open-hours {
  @extend .flex-by-column-left;
  border-bottom: solid 1px var(--eerie-grey-12);
  padding: 12px 0px;
  height: 80px;
  .day-of-week {
    @extend .title-14;
    margin-bottom: 4px;
  }
  .time-segment {
    @extend .flex-by-row-left;
    .delete-time-segment {
      margin-right: 20px;
      svg {
        font-size: 18px;
        color: #fe4945;
      }
    }

    .between-open-and-close {
      @extend .text-14;
      margin: 0px 6px;
    }
    input[name="open"],
    input[name="close"] {
      @extend .flex-by-row-centered;
      text-align: center;
      height: 32px;
      width: 60px !important;
    }
  }

  .time-segment.closed-day {
    margin: 8px 0px;
    .add-time-segment {
      @extend .flex-by-row-left;
      padding: 0px;
      svg {
        margin: 0px 6px;
      }
      span {
        @extend .text-12-cap;
        margin-left: 8px;
      }
    }
  }
  .time-segment.closed-day svg {
    font-size: 20px;
    margin-right: 18px;
  }
}

.open-hours:last-child {
  border-bottom: none;
}
