@import "../../index.scss";
.select-wrapper {
  @extend .flex-by-column-centered;
  border-radius: 4px;
  position: relative;
  .expand-toggle {
    @extend .text-14-cap;
  }
  .select-option-list {
    @extend .flex-by-column-left;
    position: absolute;
    top: 55px;
    right: 0px;
    padding: 24px 32px;
    z-index: 2;
    .select-option {
      @extend .flex-by-row-between;
      @extend .text-14-cap;
      height: 42px;
      width: 100%;
      padding-right: 40px;
      margin-bottom: 8px;
      position: relative;
      svg {
        display: none;
      }
    }
    .select-option.selected-option {
      svg {
        display: inline-block;
        position: absolute;
        right: 0px;
        font-size: 18px;
        color: var(--main-purple);
      }
    }
    .select-option:last-child {
      margin-bottom: 0px;
    }
  }
  .select-option-list::after {
    background-color: white;
    border: none;
  }
}
.select-chip {
  @extend .flex-by-row-between;
  @extend .text-14-cap;
  width: auto;
  height: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-left: 12px;
  color: var(--main-purple);
  border: 1px solid var(--main-purple);
  border-radius: 120px;
  .select-chip-name {
    @extend .flex-by-row-centered;
  }
  .select-chip-status {
    @extend .flex-by-row-centered;
    margin-left: 4px;
    width: 20px;
    svg {
      font-size: 16px;
    }
  }
}
.select-chip.selected-chip {
  background-color: var(--main-purple);
  color: white;
  opacity: 1;
}
