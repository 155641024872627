@import "./index.scss";
.checkbox {
  @extend .flex-by-row-top-left;
  position: relative;
  padding-left: 24px;
  input[type="checkbox"] {
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    padding: 0px;
    border: none;
    background-color: transparent;
    z-index: 1;
  }
  .check-mark-container {
    position: absolute;
    left: 0px;
    top: 0px;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: solid 0.5px rgba(28, 29, 33, 0.1);
    background-color: var(--ghost_white);
    .check-mark-wrapper {
      @extend .flex-by-row-centered;
      width: 16px;
      height: 16px;
      position: relative;
      .check-mark {
        font-size: 10px;
        color: white;
        pointer-events: none;
      }
      .check-mark.checked {
        color: var(--main-purple);
      }
      .check-mark.unchecked {
        color: transparent;
      }
    }
  }

  span {
    display: inline-block;
    @extend .text-12;
  }
}
