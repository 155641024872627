@import "../../../index.scss";
.unit-list {
  @extend .flex-by-column;
  position: relative;
  width: 100%;
  margin-top: 16px;
}

.unit-list:last-of-type {
  margin-bottom: 16px;
}
.unit {
  @extend .flex-by-column;
  width: 100%;
  margin-bottom: 16px;
  padding: 16px;
  box-shadow: 0px 2px 16px 0 rgba(28, 29, 33, 0.1);
  border-radius: 16px;
  background-color: white;
  position: relative;
  box-sizing: border-box;
}
.driver {
  .unit:after {
    position: absolute;
    content: "";
    left: 36px;
    top: 56px;
    width: 1px;
    height: 205px;
    border-left: 1px dashed var(--main-purple);
    z-index: 2;
  }
  .unit.show-details:after {
    display: none;
  }
  .unit:last-child:after {
    display: none;
  }
}
@media only screen and (min-width: $mobile-width) {
  .unit:after {
    display: none;
  }
}
