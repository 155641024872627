@import "../../index.scss";
nav {
  @extend .flex-by-row-between;
  @extend .card;
  width: 100vw;
  // min-width: 320px;
  height: $navbar-height-mobile;
  margin: 0px;
  padding: 0px $large-margin;
  position: fixed;
  top: 0px;
  z-index: 4;
  .brand-logo {
    //may be in nav-left or nav-middle
    @extend .text-14-upper;
    font-family: Montserrat;
    font-weight: bolder;
  }
  .nav-middle {
    .page-header {
      @extend .flex-by-row-centered;
      box-shadow: none;
      position: relative;
      background-color: transparent;
      padding: 0px;
      height: 96px;
      width: 400px;
    }
  }
  .nav-right {
    .expand-toggle {
      padding: 0px;
      height: 30px;
      .fa-user-circle {
        font-size: 26px;
      }
    }
  }
}
.nav-signed-in {
  @extend .flex-by-row-between;
}
.nav-mobile.nav-anonymous {
  @extend .flex-by-row-between;
  .nav-right {
    // position: absolute;
    right: $medium-margin;
    height: $navbar-height-mobile;
    @extend .flex-by-row-centered;
    button {
      svg {
        font-size: 16px;
      }
    }
    a {
      // border: 1px solid var(--eerie-black);
      display: block;

      @extend .flex-by-row-centered;
      span {
        @extend .text-14;
        text-transform: uppercase;
      }
    }
  }
}
.nav-desktop {
  display: none;
}
.nav-desktop.nav-anonymous {
  background-color: transparent;
  box-shadow: none;
  .nav-section-links {
    button {
      @extend .text-14;
      text-transform: capitalize;
      padding: 0px 20px;
    }
  }
  .nav-right {
    button,
    a {
      @extend .text-14-cap;
      font-weight: 500;
      color: var(--button-grey);
    }
    .section-link {
      padding: 2px 0px;
      margin-right: 40px;
    }
    .section-link.current-section {
      color: var(--main-purple);
      border-bottom: 1px solid var(--main-purple);
    }
    .login {
      border-radius: 8px;
      border: solid 1px rgba(94, 97, 110, 0.32);
      padding: 8px 20px;
      svg {
        display: none;
      }
    }
  }
}
.nav-desktop.nav-anonymous.scrolled {
  @extend .card;
}
.user-menu {
  .user-menu-overlay {
    background-color: var(--eerie-grey-25);
    z-index: 2;
  }

  .user-menu-dropdown {
    @extend .card-16-dropdown;
    position: fixed;
    top: $navbar-height-mobile;
    right: 0px;
    margin: 0px;
    padding: 0px;
    width: 100vw;
    z-index: 3;
    li {
      @extend .flex-by-column-left;
      padding: 12px 0px;
      margin: 0px 25px;
      border-bottom: 1px solid var(--eerie-grey-25);
      list-style-type: none;
      .setting-row {
        @extend .flex-by-row-between;
        @extend .text-14-cap;
        width: 100%;
        height: 24px;
        margin: 12px 0px;
        padding: 0px;
      }

      .edit-eaters {
        svg {
          font-size: 17px;
          margin-right: 0px;
        }
      }
      .edit-diets {
        svg {
          font-size: 20px;
          margin-right: 1px;
        }
      }
      .edit-favorites {
        svg {
          font-size: 19px;
          margin-right: 1px;
        }
      }
      .edit-schedule {
        svg {
          margin-right: 2px;
          font-size: 18px;
        }
      }
      .payment-method {
        svg {
          margin-right: 0px;
          font-size: 16px;
        }
      }
      .contact-abbey {
        svg {
          margin-right: 0px;
          font-size: 18px;
        }
      }
      .log-out {
        svg {
          margin-right: 0px;
          font-size: 16px;
        }
      }
    }
    li:last-of-type {
      border-bottom: none;
    }
  }
}

@media only screen and (min-width: $mobile-width) {
  nav {
    padding: 0px $main-margin;
    height: $navbar-height-desktop;
  }
  .nav-desktop {
    display: flex !important;
  }
  .nav-mobile {
    display: none !important;
  }
  .user-menu {
    .user-menu-dropdown {
      width: 300px;
      top: $navbar-height-desktop;
    }
  }
}
