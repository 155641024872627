@import "./index.scss";
.step-form-header {
  .diet-method {
    height: 40px;
    width: calc(50% - 12px);
    max-width: 160px;
    border-radius: 8px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
  }
  .diet-method:first-of-type {
    margin-right: 24px;
  }
  .diet-method.selected {
    background-color: var(--main_purple);
    border: 1px solid var(--main_purple);
    color: white;
  }
  .diet-method.unselected {
    background-color: white;
    color: rgba(28, 29, 33, 0.25);
    border: 1px solid rgba(28, 29, 33, 0.25);
  }
}

@media only screen and (min-width: 500px) {
  .diets .step-form-header {
    justify-content: center;
  }
}
