@import "./index.scss";
.user-anonymous-section.faq-page {
  .section-body {
    .info-card {
      background-color: transparent;
      border: solid 1px rgba(94, 97, 110, 0.24);
      border-radius: 8px;
      box-shadow: none;
      width: 100%;
      .faq-question {
        position: relative;
        padding-right: 32px;
        span {
          @extend .text-16;
          font-weight: bold;
          letter-spacing: 0.15px;
          color: var(--eerie-black);
        }
        .expand-toggle {
          position: absolute;
          right: 0px;
          top: 0px;
        }
      }
      .faq-answer {
        @extend .text-14;
        color: var(--landing-grey);
        letter-spacing: 0.25px;
        margin-top: 16px;
      }
    }
    .info-card.expanded {
      background-color: white;
    }
  }
}

@media only screen and (min-width: $mobile-width) {
  .user-anonymous-section.faq-page {
    background-image: url("../../../images/faq.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center;
    .section-body {
      flex-direction: column;
    }
  }
}
