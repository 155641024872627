@import "./index.scss";
.user-anonymous-section.contacts {
  .section-body {
    @extend .flex-by-row-even;
    @extend .text-14;
    flex-wrap: wrap;
    flex-direction: row;
    .item {
      margin-bottom: 16px;
      width: 200px;
      .item-title {
        font-weight: bold;
        text-transform: capitalize;
        margin-bottom: 8px;
      }
      .item-content {
        @extend .flex-by-row-left;
        svg {
          font-size: 24px;
          margin-right: 8px;
        }
      }
    }
  }
}
@media only screen and (min-width: $mobile-width) {
  .user-anonymous-section.contacts {
    .section-body {
      flex-direction: row;
      justify-content: center;
      .item {
        margin: 0px 40px;
      }
    }
  }
}
