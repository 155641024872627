@import "../../../index.scss";
$sticker-width: 280px;
.unit-details {
  @extend .flex-by-column-align-left;
  width: 100%;
  border-top: 1px solid rgba(28, 29, 33, 0.12);
  margin-top: 15px;
  padding-top: 8px;
  .unit-section {
    @extend .flex-by-column-left;
    width: 100%;
    margin-bottom: 8px;
    .unit-section-title {
      @extend .text-12-cap;
      font-weight: bold;
    }
    .unit-section-body {
      @extend .flex-by-column-left;
      width: 100%;
      .unit-sub-section {
        width: 100%;
        .eater-name {
          margin: 16px 0px;
        }
        .unit-sub-section-title {
          @extend .text-10-upper;
          @extend .flex-by-column-left;
          color: var(--eerie-grey-56);
          width: 100%;
          margin: 8px 0px;
        }
      }
      .unit-sub-section:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .unit-section.unit-section-planning {
    .unit-section-body {
      .unit-sub-section {
        //one context day
        @extend .flex-by-row-upper-left;
        flex-wrap: wrap;
        width: 100%;
        .eater-day {
          @extend .flex-by-column-left;
          width: 100%;
          margin: 0px 0px 16px 0px;
          .eater-day-title {
            @extend .title-14;
            color: var(--eerie-grey-56);
            margin-bottom: 8px;
          }
          .instance-planner {
            width: 100%;
            box-shadow: none;
            transition: box-shadow 0.1s ease-in-out;
          }
        }
      }
    }
  }

  .unit-section.unit-section-meals {
    .unit-section-body {
      .unit-sub-section {
        // one company
        .unit-sub-section-title {
          // company contact's name and address
          .formatted-row {
            width: 100%;
            @extend .overflow-ellipsis;
            .formatted-item::before {
              background-color: var(--eerie-grey-56);
            }
            .formatted-item:last-child {
              div {
                width: 100%;
                @extend .overflow-ellipsis;
              }
            }
          }
        }
        .instance-details {
          box-shadow: none;
        }
      }
    }
  }
}

@media only screen and (min-width: $mobile-width) {
  .unit-details {
    .unit-section.unit-section-planning {
      .unit-section-body {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        .unit-sub-section {
          position: relative;
          width: auto;
          .eater-day {
            width: auto;
            .instance-planner {
              width: $sticker-width;
              margin: 4px;
              box-shadow: 0 4px 12px 0 rgba(28, 29, 33, 0.12);
              background-color: transparent;
              transition: background-color 0.1s ease-in-out;
            }
            .instance-planner.just-changed {
              background-color: var(--eerie-grey-10);
              transition: background-color 0.4s ease-in-out;
            }
          }
        }
      }
    }
    .unit-section.unit-section-meals {
      .unit-section-body {
        .unit-sub-section {
          .instance-details {
            width: 100%;
            box-shadow: 0 4px 12px 0 rgba(28, 29, 33, 0.12);
          }
        }
      }
    }
    .unit-section.unit-section-timeline {
      display: none;
    }
  }
}
