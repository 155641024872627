@import "./index.scss";
.card-form {
  width: 100%;
  .form-double-field {
    @extend .flex-by-row-between;
    width: 100%;
    .form-field {
      width: 45%;
    }
    .form-field:last-child {
      margin-bottom: 16px;
    }
  }
  .form-field {
    position: relative;
    text-transform: capitalize;
    input {
      padding-left: 40px;
    }
    svg,
    input::placeholder {
      color: var(--eerie-grey-25);
    }
    .icon-wrapper {
      @extend .flex-by-row-centered;
      width: 40px;
      height: 42px;
      position: absolute;
      left: 0px;
      bottom: 0px;
      svg {
        font-size: 20px;
      }
    }
  }
}
@media only screen and (max-width: $mobile-small-width) {
  .card-form {
    .form-field {
      input {
        padding-left: 30px;
      }
      .icon-wrapper {
        width: 30px;
        svg {
          font-size: 16px;
        }
      }
    }
  }
}
