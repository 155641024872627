@import "./index.scss";
.card-saved {
  @extend .flex-by-row-between;
  @extend .text-14;
  width: 100%;
  position: relative;
  padding: 16px 0px;

  .card-info {
    text-transform: capitalize;
    svg {
      margin-right: 5px;
    }
  }
  .card-menu-toggle {
    position: relative;
  }
  .card-menu {
    top: 32px;
    right: -18px;
    padding: 16px;
    li {
      button {
        color: var(--warning-red);
      }
    }
  }
}
