@import "../../index.scss";

.homepage.setup,
.homepage.profile {
  @extend .flex-by-column-top;
  position: relative;
  background-color: white;
  padding-bottom: 0px;
}

.step-form {
  @extend .flex-by-column-top;
  position: relative;
  width: 100vw;
  max-width: $max-form-width;
  padding: 24px 16px 114px 16px;
  color: var(--eerie-black);
  background-color: white;
  z-index: 0;
  .step-form-header {
    @extend .flex-by-row-even;
    width: 100%;
    margin-bottom: 24px;
    span {
      font-size: 16px;
      text-transform: capitalize;
      font-weight: bold;
    }
  }
  .form-section-title {
    height: 24px;
    width: 100%;
    max-width: $mobile-width;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.15px;
    margin-bottom: 8px;
  }

  .step-content-card {
    @extend .card-16;
    @extend .flex-by-column-centered;
    max-width: $mobile-width;
    width: 100%;
    padding: 16px;
    margin-bottom: 24px;
    position: relative;

    .eater-name {
      width: 100%;
      @extend .flex-by-row-left;
      margin: 12px 0px 24px 0px;
    }
  }
  .step-content-card:nth-last-child(2) {
    margin-bottom: 0px;
  }
  .required-field-notes {
    @extend .flex-by-row-left;
    width: 100%;
    margin-bottom: 16px;
    span {
      font-size: 12px !important;
      font-weight: normal !important;
      text-transform: capitalize;
    }
    span:first-child {
      color: var(--main-purple);
      margin-right: 5px;
    }
  }
  .step-form-header,
  .step-content-card {
    .form-field.required-field {
      label:after {
        content: "*";
        color: var(--main-purple);
        margin-left: 3px;
      }
    }
    .form-field.required-field.invalid {
      input {
        border-color: var(--warning_red);
      }
    }
  }

  .form-field {
    .geosuggest {
      padding: 0px;
    }
  }
}

.homepage.profile {
  padding-top: 0px;
  .step-form {
    min-height: 100vh;
    padding-top: calc(#{$navbar-height-mobile} + 30px);
    .step-form-header {
      margin: 20px auto;
    }
    .step-content-card:first-child {
      margin-top: 20px;
    }
  }
}
.homepage.setup {
  .page-header {
    position: relative;
    padding: 0px 24px;
    margin-top: 16px;
  }
  .step-form {
    padding-top: 24px;
    min-height: calc(100vh - 96px - #{$navbar-height-mobile});
  }
}

.goto-confirm {
  @extend .flex-by-column-between;
  padding: 40px;
  background-color: white;
  border-radius: 16px;
  position: fixed;
  top: calc(50vh - 80px);
  left: 16px;
  width: calc(100vw - 32px);
  height: 160px;
  span {
    @extend .text-14;
    text-align: center;
    margin-bottom: 24px;
  }
  .button-wrapper {
    @extend .flex-by-row-space-around;
    button {
      height: 42px;
      width: 100px;
      color: white;
      border-radius: 8px;
    }
    button.save-goto {
      background-color: var(--main-purple);
      margin-left: 32px;

      box-shadow: 0 8px 32px 0 rgba(95, 92, 255, 0.24);
    }
    button.no-save-goto {
      background-color: var(--warning-red);
      box-shadow: 0 8px 32px 0 rgba(254, 73, 69, 0.24);
    }
  }
}
@media only screen and (min-width: $mobile-width) {
  .homepage.profile,
  .homepage.setup {
    padding-top: 0px;
  }
  .homepage.profile {
    .step-form {
      padding-top: calc(#{$navbar-height-desktop} + 30px);
      min-height: calc(100vh - #{$navbar-height-desktop});
    }
  }
  .homepage.setup {
    .page-header {
      z-index: -1;
    }
    .step-form {
      padding-top: 32px;
      min-height: calc(100vh - #{$navbar-height-desktop} - 96px);
    }
  }
  .goto-confirm {
    width: 400px;
    left: calc(50vw - 200px);
  }
}
