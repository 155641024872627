$calendar-width: 310px;
$daily-width: 360px;
$main-margin: 40px;
$large-margin: 32px;
$medium-margin: 16px;
$mobile-width: calc(#{$calendar-width} + #{$daily-width} + #{$main-margin} * 3);
$mobile-small-width: 400px;
$medium-desktop-width: 1200px;
$navbar-height-mobile: 80px;
$navbar-height-desktop: 96px;
$max-form-width: 400px;
:export {
  smallMobileWidth: 400;
  mobileWidth: 758;
  mediumDesktopWidth: 1200;
}
html,
body,
.root {
  margin: 0;
  padding: 0;
  background: white;
  font-family: Noto Sans;
  height: 100vh;
  width: 100%;
}

/* shared rules */

textarea,
button,
input,
select {
  /*font-family: Montserrat;*/
  font-family: Noto Sans;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: transparent;
}

ul,
li {
  list-style-type: none;
  text-decoration: none;
}
input,
button,
textarea {
  box-shadow: none;
}

button:hover,
a:hover {
  cursor: pointer;
}

/* specific element rules */

input {
  @extend .text-16;
  margin: 4px 0px 16px 0px;
  background-color: #f7f9f9;
  height: 42px;
  border-radius: 8px;
  border: none;
  padding: 8px;
  border: solid 0.5px rgba(28, 29, 33, 0.1);
}
select {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-appearance: none;
  text-transform: capitalize;
}

textarea {
  resize: none;
  border-radius: 0;
}

button {
  background-color: transparent; /*whenever a specific button needs a color, it will define in its own file*/
  border: none;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.7px;
}

a {
  text-decoration: none;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
}
a:visited,
a:active {
  color: black;
}

/* reusable class rules */

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: fixed !important;
  top: 0;
  left: 0;
}

.content {
  width: 100vw;
  min-width: 320px;
  z-index: 1;
  position: relative;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 100px;
  background: #f7f9f9;
  z-index: 3;
}

.success,
.alert {
  /* height: 40px; */
  line-height: 40px;
  font-size: 13px;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 250px;
  text-align: left;
  padding: 0 0 0 10px;
  box-sizing: border-box;
  letter-spacing: 0.4px;
  margin: 0 5px;
  align-items: center;
  display: flex;
  white-space: normal;
  line-height: normal;
  font-size: 12px;
  border-radius: 16px;
  background-color: #fe4945;
  width: 189px;
  padding: 0px 10px;
  /* height: 24px; */
  line-height: 24px;
  margin: 12px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert {
  background: #ff6347;
}
.success {
  background-color: green;
}
.empty-alert {
  background: white;
  height: 40px;
  width: 250px;
}
.info-label,
.info-text {
  text-transform: capitalize;
  margin-right: 5px;
  text-align: left;
}
.info-label {
  font-weight: 200;
}
.info-text {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.info-text.unfinished {
  color: red;
}

.homepage {
  min-height: 100vh;
  position: relative;
  background: white;
  justify-content: flex-start;
  background-color: var(--ghost-white);
  padding-bottom: 100px;
  padding-top: $navbar-height-mobile;
}
.homepage.freeze {
  overflow: hidden;
}

div,
nav,
textarea,
form,
input,
button,
select {
  box-sizing: border-box;
}
::-webkit-input-placeholder {
  color: #adadad;
}

input[type="submit"] {
  background: black;
  color: white;
  width: 240px;
  height: 50px;
  font-size: 13px;
  font-weight: 500;
  margin-top: 50px;
}

body select {
  letter-spacing: 0.5px;
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* csslint ignore:start */
  -webkit-box-shadow: 0 0 0px 1000px #f7f9f9 inset !important;
  /* csslint ignore:end */
}
.cancel-button {
  color: #5f5cff;
  font-size: 14px;
  font-weight: 500;
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* csslint ignore:start */
html {
  --main-purple: #5f5cff;
  --main_purple: #5f5cff;
  --eerie-black: #1c1d21;
  --eerie_black: #1c1d21;
  --ghost_white: #f7f9f9;
  --ghost-white: #f7f9f9;
  --warning_red: #fe4945;
  --warning-red: #fe4945;
  --main-orange: #f87211;
  --light-green: #a0e8af;
  --dark-green: #30967a;
  --dark_green: #30967a;
  --main_pink: #ff9ad7;
  --main-pink: #ff9ad7;
  --main-blue: #9ddcff;
  --main_blue: #9ddcff;
  --eerie-grey-10: rgba(28, 29, 33, 0.1);
  --eerie-grey-12: rgba(28, 29, 33, 0.12);
  --eerie-grey-25: rgba(28, 29, 33, 0.25);
  --eerie-grey-56: rgba(28, 29, 33, 0.56);
  --eerie-grey-80: rgba(28, 29, 33, 0.8);
  --background-grey-25: rgba(227, 228, 232, 0.25);
  --background-grey-50: rgba(227, 228, 232, 0.5);
  --background-grey-75: rgba(227, 228, 232, 0.75);
  --background-grey-100: rgba(227, 228, 232, 1);
  --background-green-75: rgba(216, 243, 235, 0.75);
  --background-light-purple: rgba(205, 204, 255, 0.5);
  --shadow-purple: rgba(95, 92, 255, 0.24);
  --button-grey: #5e616e;
  --mobile-width: 600px;
  --landing-orange: #fc8435;
  --landing-green: #b4e4b4;
  --landing-grey: #383a42;
  --landing-blue: #f3fbff;
}

h2 {
  width: 100%;
  text-align: center;
}
.select-button,
input {
  width: 100%;
  box-sizing: border-box;
  height: 42px;
  border-radius: 8px;
  border: solid 0.5px rgba(28, 29, 33, 0.1);
  background-color: var(--ghost_white);
  justify-content: space-between;
  display: flex;
  align-items: center;
  color: var(--eerie_black);
  padding: 0px 12px 0px 8px;
  margin: 0px;
}
.select-button {
  text-transform: capitalize;
}
.select-button span {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.4px;
}
.select-button svg {
  opacity: 0.24;
  font-size: 16px;
  margin: 0px;
}

.freeze {
  overflow: auto;
  position: fixed;
  /* height: 100vh; */
  display: block;
}
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* rewrite widgets starts*/
button {
  width: auto;
  white-space: nowrap;
}
.page-content {
  @extend .flex-by-column-centered;
  padding: 0px;
}

.flex-by-row {
  display: flex;
  flex-direction: row;
}
.flex-by-column {
  display: flex;
  flex-direction: column;
}

.flex-by-row-centered {
  @extend .flex-by-row;
  justify-content: center;
  align-items: center;
}
.flex-by-row-top {
  @extend .flex-by-row;
  justify-content: center;
  align-items: flex-start;
}
.flex-by-row-stretch {
  @extend .flex-by-row;
  justify-content: center;
  align-items: stretch;
}
.flex-by-row-top-left {
  @extend .flex-by-row;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-by-row-even {
  @extend .flex-by-row;
  justify-content: space-evenly;
  align-items: center;
}

.flex-by-column-centered {
  @extend .flex-by-column;
  justify-content: center;
  align-items: center;
}
.flex-by-column-stretch {
  @extend .flex-by-column;
  justify-content: center;
  align-items: stretch;
}
.flex-by-column-left {
  @extend .flex-by-column;
  justify-content: center;
  align-items: flex-start;
}
.flex-by-column-between {
  @extend .flex-by-column;
  justify-content: space-between;
  align-items: center;
}
.flex-by-column-top-left {
  @extend .flex-by-column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-by-column-left-between {
  @extend .flex-by-column;
  justify-content: space-between;
  align-items: flex-start;
}
.flex-by-column-top {
  @extend .flex-by-column;
  justify-content: flex-start;
  align-items: center;
}

.flex-by-column-upper-left {
  @extend .flex-by-column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-by-row-left {
  @extend .flex-by-row;
  justify-content: flex-start;
  align-items: center;
}
.flex-by-row-left-bottom {
  @extend .flex-by-row;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-by-row-right {
  @extend .flex-by-row;
  justify-content: flex-end;
  align-items: center;
}

.flex-by-row-upper-left {
  @extend .flex-by-row;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-by-row-upper-right {
  @extend .flex-by-row;
  justify-content: flex-end;
  align-items: flex-start;
}

.flex-by-row-between {
  @extend .flex-by-row;
  justify-content: space-between;
  align-items: center;
}
.flex-by-row-between-top {
  @extend .flex-by-row;
  justify-content: space-between;
  align-items: flex-start;
}
.flex-by-row-space-around {
  @extend .flex-by-row;
  justify-content: space-around;
  align-items: center;
}

.flex-by-column-align-left {
  @extend .flex-by-column;
  justify-content: flex-start;
}
.text-20 {
  line-height: 27px;
  font-size: 20px;
}
.text-34 {
  height: 46px;
  font-size: 34px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  color: var(--eerie_black);
}
.title-34 {
  @extend .text-34;
  font-weight: bold;
  text-transform: capitalize;
}
.title-32 {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: 1.5px;
}
.title-26 {
  font-size: 26px;
  line-height: 26px;
  font-weight: 700;
  letter-spacing: 1.5px;
}
.title-22 {
  font-size: 22px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.title-20 {
  line-height: 27px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.15px;
  text-transform: capitalize;
  color: #000000;
}

.title-16 {
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.15px;
  color: var(--eerie_black);
  text-transform: capitalize;
}

.title-14 {
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.25px;
  color: var(--eerie_black);
  text-transform: capitalize;
}
.text-16 {
  line-height: 28px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
}
.text-16-cap {
  @extend .text-16;
  text-transform: capitalize;
}
.text-14-button {
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.1px;
  text-transform: capitalize;
  color: var(--ghost_white);
}
.text-14 {
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: var(--eerie_black);
  text-transform: none;
}
.text-14-cap {
  @extend .text-14;
  text-transform: capitalize;
}

.text-14-upper {
  @extend .text-14;
  text-transform: uppercase;
}
.text-12 {
  line-height: 16px;
  font-size: 12px;
  letter-spacing: 0.4px;
  color: var(--eerie_black);
  text-transform: normal;
}

.text-12-cap {
  @extend .text-12;
  text-transform: capitalize;
}

.text-12-upper {
  @extend .text-12;
  text-transform: uppercase;
}

.title-12 {
  @extend .text-12-cap;
  font-weight: bold;
}
.text-10 {
  line-height: 16px;
  font-size: 10px;
  letter-spacing: 1.5px;
}
.text-10-upper {
  @extend .text-10;
  text-transform: uppercase;
}
.title-10 {
  @extend .text-10-upper;
  font-weight: bold;
}
.text-9 {
  line-height: 14px;
  font-size: 9px;
  letter-spacing: 1.2px;
}
.text-9-upper {
  @extend .text-9;
  text-transform: uppercase;
}

.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.grey-button {
  border-radius: 8px;
  background: var(--button-grey);
  color: white;
}

.text-box {
  @extend .text-12-cap;
  @extend .flex-by-row-left;
  height: 42px;
  border-radius: 8px;
  padding: 0px 8px;
  border: solid 0.5px var(--eerie-grey-10);
  background-color: var(--ghost_white);
}

.card {
  box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.12);
  background-color: white;
}

.card-16 {
  @extend .card;
  border-radius: 16px;
}
.card-16-dropdown {
  box-shadow: 0 32px 32px 0 rgba(28, 29, 33, 0.12);
  background-color: white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.card-8 {
  @extend .card;
  border-radius: 8px;
}
.card-textarea {
  @extend .card-8;
  background-color: var(--ghost-white);
  border: 0.3px solid var(--eerie-grey-10);
  box-shadow: none;
}
.card-input {
  @extend .card-8;
  margin: 0px;
  border: solid 0.5px var(--eerie-grey-10);
  background-color: var(--ghost_white);
  box-shadow: none;
  padding-left: 17px;
}
.dot-2 {
  background-color: var(--eerie-black);
  width: 2px;
  height: 2px;
  border-radius: 50%;
}
.dot-3 {
  background-color: var(--eerie-black);
  width: 3px;
  height: 3px;
  border-radius: 50%;
}
.dot-4 {
  background-color: var(--eerie-black);
  width: 4px;
  height: 4px;
  border-radius: 50%;
}
.round-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  @extend .flex-by-row-centered;
  @extend .title-16;
}

.deleted-icon {
  color: var(--background-grey-75);
  border: 1px solid var(--background-grey-75);
  position: relative;
}

.deleted-icon::after {
  content: "";
  height: 100%;
  width: 1px;
  background-color: var(--background-grey-75);
  position: absolute;
  transform: rotate(45deg);
}

.purple-icon {
  color: var(--main-purple);
  background-color: var(--background-light-purple);
}

.grey-icon {
  color: var(--eerie-grey-56);
  background-color: var(--background-grey-50);
}
.green-icon {
  color: var(--dark-green);
  background-color: var(--background-green-75);
}

.selected {
  background-color: var(--main-purple);
  color: var(--ghost-white);
}

.unselected {
  border: solid 1px var(--eerie-grey-25) !important;
  background-color: transparent !important;
  color: var(--eerie-grey-25) !important;
}

.button-purple {
  @extend .selected;
  @extend .title-14;
  @extend .flex-by-row-centered;
  border-radius: 8px;
  box-shadow: 0 0 1px 0 rgba(95, 92, 255, 0.04),
    0 2px 6px 0 rgba(95, 92, 255, 0.04), 0 16px 24px 0 rgba(95, 92, 255, 0.06);
}
.button-transparent {
  @extend .title-14;
  @extend .flex-by-row-centered;
  background: transparent;
  color: var(--eerie-black);
  border-radius: 8px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.04),
    0 10px 20px 0 rgba(0, 0, 0, 0.04);
}

.green-card-no-border {
  background: var(--light-green);
  color: var(--dark-green);
}
.green-card-with-border {
  @extend .green-card-no-border;
  border: 1px solid var(--dark-green);
}
.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
}
.overlay-grey {
  @extend .overlay;
  background-color: var(--eerie-grey-25);
}
.form-field {
  @extend .flex-by-column-stretch;
  width: 100%;
  margin-bottom: 16px;
  label {
    @extend .text-12-cap;
    margin-bottom: 4px;
  }
}
.form-field.has-error {
  input {
    border: 1px solid var(--warning-red);
  }
}
.form-field:last-child {
  margin-bottom: 0px;
}
.react-confirm-alert {
  z-index: 300;
}
.quick-link {
  @extend .card-8;
  @extend .flex-by-row-centered;
  @extend .text-12;
  width: 125px;
  height: 40px;
  text-transform: uppercase;
}

@media only screen and (min-width: $mobile-width) {
  .homepage {
    padding-top: $navbar-height-desktop;
  }
  .page-content {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    // padding: $navbar-height-desktop 0px;
  }
}
/* rewrite widgets ends*/

/* csslint ignore:end */
