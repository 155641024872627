@import "../../../index.scss";
.delivery-comment {
  @extend .card-textarea;
  @extend .flex-by-row-centered;
  width: 100%;
  padding: 13px 8px;
  textarea {
    @extend .text-12;
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 0px;
    margin: 0px;
  }
}
