@import "../../index.scss";
.formatted-row {
  @extend .flex-by-row-left;
}
.formatted-item {
  position: relative;
  margin-right: 16px;
}

.formatted-item:last-of-type {
  margin-right: 0px;
}

.bulletted .formatted-item::before {
  content: "";
  position: absolute;
  left: -9px;
  top: 6px;
  @extend .dot-3;
}
.bulletted .formatted-item:first-of-type::before {
  display: none;
}
