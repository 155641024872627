@import "./index.scss";
// mobile
.page-content {
  .day-details {
    width: calc(100vw - #{$medium-margin} * 2);
    margin: 0px $medium-margin;
    z-index: 1;
    .day-details-header {
      @extend .flex-by-row-between;
      .date-header-mobile {
        width: 100%;
        @extend .flex-by-row-centered;
        @extend .title-16;
        height: $header-height;
      }
      .date-header-desktop {
        @extend .flex-by-row-left;
        @extend .title-20;
        display: none;
        height: $header-height;
      }
      .quick-link {
        display: none;
      }
    }
  }
}

// desktop
@media only screen and (min-width: $mobile-width) {
  .page-content {
    position: relative;
    .day-details {
      margin: 0px $main-margin 0px 0px;
      width: calc(100% - #{$main-margin} * 3 - #{$calendar-width});
      .day-details-header {
        .date-header-mobile {
          display: none;
        }
        .date-header-desktop {
          display: flex;
          height: $header-height;
        }
        .quick-link {
          display: flex;
        }
      }
    }
  }
}
