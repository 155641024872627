@import "./index.scss";
.calendar-container {
  width: calc(100vw - #{$medium-margin} * 2);
  margin: 0px $medium-margin;
  flex-shrink: 0;
  z-index: 1;
  .calendar-header {
    @extend .flex-by-row-between;
    .calendar-navigation {
      @extend .flex-by-row-between;
      @extend .title-16;
      text-align: center;
      width: 230px;
      height: $header-height;
      button {
        font-size: 22px;
        padding: 0px 16px;
      }
      .full-month {
        display: inline-block;
      }
      .short-month {
        display: none;
      }
    }
  }

  .calendar-card {
    @extend .card-16;
    padding: $medium-margin 0px;
    > div {
      @extend .flex-by-row-centered;
      > div {
        width: calc(100% / 7);
      }
    }
    .days-of-week {
      @extend .text-16;
      color: var(--eerie-grey-56);
      height: 40px;
      padding-bottom: 5px;
      text-transform: uppercase;
      .day-of-week {
        text-align: center;
      }
    }
    .days-of-month {
      .calendar-column {
        @extend .flex-by-column-centered;
        .calendar-day {
          padding: 0px;
        }
      }
    }
  }
}
.page-content.driver {
  .calendar-container {
    .calendar-header {
      @extend .flex-by-row-centered;
      .calendar-navigation {
        width: 100%;
        max-width: 100%;
        padding: 0px 32px;
        .full-month {
          display: inline-block;
        }
        .short-month {
          display: none;
        }
      }
    }
  }
}
//desktop
@media only screen and (min-width: $mobile-width) {
  .calendar-container {
    max-width: $calendar-width;
    margin: 0px $main-margin;
    .calendar-header {
      width: calc(100vw - #{$main-margin} * 2);
      .calendar-navigation {
        width: $calendar-width;
        font-size: 20px;
        .full-month {
          display: inline-block;
        }
        .short-month {
          display: none;
        }
      }
      .quick-link {
        display: none;
      }
    }
  }
  .page-content.driver {
    .calendar-container {
      .calendar-header {
        justify-content: flex-start;
        .calendar-navigation {
          width: $calendar-width;
          .full-month {
            display: inline-block;
          }
          .short-month {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-small-width) {
  .calendar-container {
    .calendar-header {
      .calendar-navigation {
        width: 55%;
        max-width: 200px;
        padding: 0px 16px;
        .full-month {
          display: none;
        }
        .short-month {
          display: inline-block;
        }
        button {
          font-size: 16px;
          padding: 0px;
        }
      }
      .quick-link {
        width: 35%;
        min-width: 90px;
      }
    }
  }
}
