@import "./index.scss";
.list {
  width: 100%;
  button {
    position: relative;
    width: 100%;
    .list-entry {
      width: 100%;
      .sticker-content {
        @extend .flex-by-row-between;
        width: 100%;
        .name {
          @extend .overflow-ellipsis;
          display: block;
          max-width: calc(100% - 80px);
        }
        .price {
          margin-left: 10px;
          position: absolute;
          right: 20px;
        }
      }
    }
    .entry-actions {
      position: absolute;
      top: 72px;
      right: 0px;
      width: 150px;
      z-index: 3;
      li {
        @extend .flex-by-row-between;
        @extend .text-14-cap;
        width: 100%;
        padding: 15px 0px;
        a {
          @extend .flex-by-row-between;
          width: 100%;
          padding: 0px;
        }
        svg {
          margin: 0px;
          font-size: 14px;
        }
      }
      li.delete-dish {
        color: var(--warning_red);
      }
    }
  }
}
