@import "../../../index.scss";
//shared by mobile and desktop
.unit-icon {
  position: absolute;
  top: 16px;
  left: 16px;
}
.unit-name {
  @extend .title-16;
  @extend .flex-by-row-between;
}
.unit-address {
  @extend .text-12-cap;
  @extend .overflow-ellipsis;
}
.unit-summary {
  @extend .text-10-upper;
  @extend .overflow-ellipsis;
  margin-top: 4px;
  .formatted-row {
    flex-wrap: wrap;
  }
}
.unit-status {
  margin-top: 8px;
}

.unit-header-desktop,
.unit-header-mobile {
  position: relative;
  width: 100%;
  padding-left: 56px;
}

$button-width: 150px;
$button-height: 32px;
$button-margin: 8px;
$expand-button-width: 40px;

.unit-header-desktop {
  @extend .flex-by-row-between-top;
  display: none;
  .unit-header-left-section {
    width: calc(
      100% - #{$button-width} - #{$expand-button-width} - #{$button-margin} * 3
    );
    margin-right: $button-margin;
    .unit-status {
      margin-top: 32px;
    }
  }
  .unit-header-right-section {
    @extend .flex-by-column-centered;
    padding-right: $expand-button-width;

    .button-click-to-copy button {
      @extend .text-12;
      @extend .flex-by-row-centered;
      color: var(--button-grey);
      width: $button-width;
      height: $button-height;
      margin-bottom: $button-margin;
      margin-right: $button-margin;
      padding-left: 8px;
      svg {
        font-size: 14px;
        margin-right: $button-margin;
      }
    }
    .expand-toggle {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 40px;
    }
  }
}

.unit-header-mobile {
  @extend .flex-by-column-stretch;
  .unit-address {
    margin-right: 40px;
  }
  .unit-header-lower-section {
    @extend .flex-by-column-left;
    width: 100%;
    max-width: 200px;
    a,
    button,
    div {
      width: 100%;
    }
    .button-call-to-action {
      @extend .text-12-cap;
      @extend .flex-by-row-left;
      margin: 8px 0px 0px 0px;
      color: var(--button-grey);
      font-weight: 200;
    }

    .unit-status {
      width: 100%;
      .time-label div:first-child {
        @extend .overflow-ellipsis;
      }
    }
  }
}

// medium desktop
@media only screen and (min-width: $mobile-width) {
  .unit-header-desktop {
    display: flex;
    .unit-status {
      width: 100%;
      overflow: visible;
    }
  }
  .unit-header-mobile {
    display: none;
  }
}
$button-width: 200px;
$button-height: 40px;
$button-margin: 16px;
// large desktop
@media only screen and (min-width: $medium-desktop-width) {
  .unit-header-desktop {
    .unit-header-left-section {
      width: calc(
        100% - #{$button-width * 2} - #{$button-margin * 3} - #{$expand-button-width}
      );
      margin-right: $button-margin;
      .unit-status {
        margin-top: 8px;
      }
    }
    .unit-header-right-section {
      flex-direction: row;
      .button-click-to-copy button {
        justify-content: center;
        font-size: 14px;
        letter-spacing: 0.1px;
        width: auto;
        height: $button-height;
        padding: 15px;
        margin-bottom: 0px;
        margin-right: $button-margin;
        svg {
          font-size: 20px;
        }
      }
    }
  }
}
