@import "../../../index.scss";
.meal-status {
  box-shadow: none;
  .status-title,
  .status-timestamp {
    @extend .overflow-ellipsis;
    @extend .text-12-cap;
  }
  .status-timestamp {
    font-weight: normal;
  }
}
@media only screen and (min-width: $mobile-width) {
  .meal-status {
    box-shadow: 0 4px 12px 0 rgba(28, 29, 33, 0.12);
  }
}
