@import "./index.scss";
.entity-field-content.statuses {
  @extend .flex-by-row-between;
  padding: 14px 0px;
  width: 100%;
  .status {
    @extend .flex-by-column-centered;
    width: 25%;
    position: relative;
    .status-index {
      @extend .flex-by-row-centered;
      background: var(--eerie-grey-12);
      color: var(--eerie_black);
      font-weight: 600;
      width: 32px;
      height: 32px;
      padding: 0px;
      border-radius: 50%;
      margin-bottom: 10px;
      svg {
        font-size: 16px;
      }
    }
    .status-text {
      @extend .title-10;
    }
  }
  .status.approved,
  .status.disapproved {
    .status-index {
      color: white;
      opacity: 1;
    }
  }
  .status.approved {
    .status-index {
      background-color: var(--main-purple);
    }
  }
  .status.disapproved {
    .status-index {
      background-color: var(--warning-red);
    }
  }

  .status.testing {
    .status-index {
      background-color: transparent;
      border: 2px solid var(--main-purple);
      color: var(--main-purple);
    }
  }
  .status:after {
    content: "";
    position: absolute;
    height: 0px;
    width: calc(100% - 33px);
    border: 1px dashed var(--eerie-grey-12);
    left: calc(50% + 15px);
    top: 15px;
  }
  .status.approved:after {
    border: 1px solid var(--main-purple);
  }
  .status:last-child:after {
    display: none;
  }
}
.entity-field-content.diets {
  padding-top: 8px;
  .checkbox {
    margin-bottom: 5px;
  }
  .checkbox:last-child {
    margin-bottom: 0px;
  }
}
.entity-field-content.restaurant-name {
  .sticker {
    width: 100%;
  }
}
@media only screen and (min-width: $mobile-width) {
  .entity-field-content.statuses {
    left: -16px;
    width: calc(100% + 16px);
  }
}
