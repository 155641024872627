@import "../../../index.scss";
.calendar-instances {
  @extend .flex-by-row-centered;
  .meal-dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }

  .meal-dot.dinner {
    background-color: var(--main_blue);
  }

  .meal-dot.lunch {
    background-color: var(--main_pink);
  }

  .meal-dot.all-cancelled {
    opacity: 0.25;
  }
  .meal-dot:nth-child(2) {
    margin-left: 3px;
  }
}
