@import "./index.scss";
.step-form.eaters {
  .step-form-header {
    @extend .flex-by-row-between;
    .eater-counter {
      @extend .flex-by-row-centered;
      button {
        padding: 0px;
        margin: 0px;
      }
      button:disabled {
        opacity: 0.25;
      }
    }

    .eater-counter button svg {
      margin: 0px;
      font-size: 26px;
      color: var(--eerie-black);
    }

    .eater-counter span {
      width: 34px;
      text-align: center;
      font-size: 16px;
    }
  }

  .step-content-card {
    .eater-contact-header {
      @extend .flex-by-row-between;
      width: 100%;
      margin: 12px 0px 24px 0px;
      .eater-index {
        @extend .title-16;
      }
      .eater-name {
        width: auto;
        margin: 0px;
      }
      .delete-eater {
        padding: 0px;
        width: auto;
        svg {
          font-size: 20px;
          margin: 0px;
        }
      }
    }
    .contact-name {
      text-transform: capitalize;
    }
  }
  .step-content-card.first-eater input[type="tel"] {
    border: none;
    pointer-events: none;
  }

  .step-content-card.phone-invalid {
    input[type="tel"] {
      border-color: var(--warning_red);
    }
  }
}
