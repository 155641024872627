@import "./index.scss";
.add-entry {
  @extend .flex-by-row-left;
  @extend .text-14-cap;
  margin: 16px 14px 6px 6px;
  svg {
    font-size: 20px;
    margin-right: 10px;
  }
}
