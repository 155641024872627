.confirm-window {
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center
}
.confirm-alert {
  border: solid 1px rgb(217, 217, 217);
  border-radius: 16px;
  z-index: 5;
  background: white;
  box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, .16);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.confirm-buttons {
  display:flex; 
  width: 100%; 
  justify-content:space-around; 
  align-items:center;
}
.confirm-buttons button {
  font-size: 12px;
  text-transform: none;
  padding: 10px 20px;
  margin: 20px;
  border-radius: 8px;
}
.yes-button {
  color: white;
  background: #5f5cff;
  box-shadow: 0 8px 32px 0 rgba(95, 92, 255, 0.24);
}
.no-button {
  color: rgba(28, 29, 33, 0.8);
  /*border: 1px solid rgba(28, 29, 33, 0.5);*/
  background: transparent;
  box-shadow: 0 8px 32px 0 rgba(28, 29, 33, 0.24);

}