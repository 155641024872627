@import "../../../index.scss";
.team-meal {
  @extend .card-16;
  margin: 16px 0px;
  padding-top: 16px;
  border-left: 16px solid;
}
.team-meal:first-child {
  margin-top: 0px;
}
.team-meal.lunch {
  border-color: var(--main_pink);
}
.team-meal.dinner {
  border-color: var(--main_blue);
}

.team-summary {
  @extend .flex-by-column-left;
  position: relative;
  .expand-toggle {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
  }

  .team-summary-row {
    @extend .flex-by-row-left;
    @extend .text-14-cap;
    width: 100%;
    height: auto !important;
    margin-bottom: 16px;
    padding: 0px 16px 0px 46px;
    position: relative;
    .icon-wrapper {
      position: absolute;
      top: 0px;
      left: 16px;
      width: 30px;
      font-size: 20px;
    }
    .info-content {
      @extend .overflow-ellipsis;
    }

    .info-timestamp {
      @extend .text-12;
      font-size: 11px;
      opacity: 0.5;
    }
  }

  .team-summary-row.rating-info {
    @extend .flex-by-column-left;
    padding: 0px 16px;
    .rating-info-wrapper {
      @extend .flex-by-row-between-top;
      width: 100%;
      .expand-toggle {
        span {
          font-weight: bold;
        }
        svg {
          font-size: 12px;
        }
      }
      .select-option-list {
        top: 35px;
        padding: 12px 16px;
        .select-option {
          height: 24px;
          margin: 4px 0px;
        }
      }
    }
  }
  .team-summary-row.comment-info {
    @extend .flex-by-column-left;
    padding: 0px 16px;
    .info-timestamp {
      margin-top: 5px;
    }
  }
  .team-summary-row.scheduled-eaters {
    flex-wrap: wrap;
    margin-bottom: 8px;
    .eater {
      @extend .flex-by-row-centered;
      @extend .text-12-cap;
      position: relative;
      margin: 0px 8px 8px 0px;
      padding: 0px 8px;
      height: 24px;
      border-radius: 8px;
      color: white;
      background-color: var(--light-green);
      svg {
        margin-right: 5px;
        margin-bottom: 3px;
        font-size: 10px;
      }
      .too-late {
        @extend .text-14;
        top: 35px;
        left: calc(-50px + 50%);
        pointer-events: none;
        width: 100px;
      }
    }
    .eater.cancelled {
      background-color: white;
      color: var(--light-green);
      border: solid 1px var(--light-green);
    }
  }
  .team-button {
    z-index: 2;
    position: absolute;
    top: 0px;
    right: 16px;
  }

  .team-button-menu {
    right: -4px;
    top: 40px;
    button.cancel-team {
      color: #fe4945;
    }
    button:disabled {
      opacity: 0.56;
    }
  }
  .team-button-menu.too-late {
    min-width: 0px;
    li {
      span {
        text-transform: none;
      }
    }
  }
}
.expand-toggle.team-details {
  @extend .flex-by-row-between;
  @extend .text-14;
  text-transform: capitalize;
  padding: 0px 16px;
  position: relative;
  height: 57px;
  right: 0px;
  top: auto;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid var(--eerie-grey-10);
  svg {
    font-size: 12px;
  }
}
.expand-toggle.team-details.see-more {
  border-bottom: 1px solid var(--eerie-grey-10);
}
