@import "./index.scss";

.dashboard-nav {
  @extend .card;
  @extend .flex-by-column-centered;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 4;
  h2 {
    @extend .title-16;
    margin: 24px 0px;
  }
  .data-shuffle {
    @extend .flex-by-row-between;
    align-items: flex-end;
    justify-content: stretch;
    width: 100%;
    padding: 0px 16px 16px 16px;
    .data-sort {
      @extend .flex-by-column-left;
      flex-grow: 1;
      margin-right: 8px;
      position: relative;
      label {
        margin-right: 10px;
        padding: 0px;
        font-size: 10px;
        text-transform: uppercase;
        height: 16px;
        line-height: 16px;
        letter-spacing: 1.5px;
        margin-bottom: 2px;
      }
      select {
        width: 100%;
        height: 32px;
        border-radius: 8px;
        padding: 0px 8px;
        border: solid 0.5px rgba(28, 29, 33, 0.3);
        background-color: var(--ghost_white);
        font-size: 12px;
        font-weight: 600;
      }
      svg {
        position: absolute;
        font-size: 12px;
        bottom: 10px;
        right: 0px;
        margin-right: 12px;
        pointer-events: none;
      }
    }

    .data-reload {
      @extend .flex-by-row-centered;
      padding: 0px;
      width: 32px;
      height: 32px;
      svg {
        font-size: 18px;
      }
    }

    .toggle-map.list-view {
      opacity: 0.25;
    }
  }

  .data-buttons-wrapper {
    width: 100%;
    height: 96px;

    .data-buttons {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      display: flex;
      flex-wrap: nowrap;
      padding: 0px 16px;
      width: auto;
      .data-button {
        box-sizing: border-box;
        margin-right: 4px;
        padding-top: 12px;
        width: 80px;
        height: 80px;
        border-radius: 8px;
        border: 1px solid rgba(28, 29, 33, 0.25);
        position: relative;
        color: var(--eerie-grey-25);
        flex-shrink: 0;
        .button-content {
          @extend .flex-by-column-centered;
          svg {
            font-size: 16px;
          }
          .data-button-text {
            @extend .flex-by-column-centered;
            width: 100%;
            .data-type {
              @extend .text-12-cap;
              color: var(--eerie-grey-25);
              font-weight: 300;
            }
            .number-of-entries {
              @extend .text-10;
              color: var(--eerie-grey-25);
            }
          }
        }
      }
      .data-button:hover {
        color: var(--main-purple);
        border-color: var(--main-purple);
        .button-content {
          .data-button-text {
            .data-type,
            .number-of-entries {
              color: var(--main-purple);
            }
          }
        }
      }

      .data-button.selected {
        border: none;
        background: var(--main-purple);
        color: white;
        .button-content {
          .data-type {
            color: white;
          }
          .number-of-entries {
            color: white;
          }
        }
      }
      .data-button.selected:hover {
        .button-content {
          .data-button-text {
            .data-type,
            .number-of-entries {
              color: white;
            }
          }
        }
      }
      .data-button:nth-of-type(3) {
        margin-left: 32px;
        position: relative;
      }
      .data-button:nth-of-type(3):before {
        content: "";
        width: 1px;
        height: 80px;
        background-color: var(--eerie-grey-25);
        position: absolute;
        top: 0px;
        left: -19px;
      }
      .data-button:last-child::after {
        content: "";
        width: 16px;
        height: 80px;
        display: block;
        position: absolute;
        left: 100%;
        top: 0px;
      }
    }
  }

  .dashboard-nav::-webkit-scrollbar {
    width: 0 !important;
  }

  .close-page {
    position: fixed;
    right: 0px;
    margin: 24px;
  }
}

@media only screen and (min-width: $mobile-width) {
  .dashboard-nav {
    height: 100vh;
    width: 200px;
    padding: 32px 0px 0px 0px;
    flex-direction: column-reverse;
    justify-content: flex-end;
    .close-page {
      margin-top: 32px;
    }
    h2 {
      display: none;
    }
    .data-shuffle {
      flex-direction: column;
      width: 148px;
      padding: 0px;
      align-items: stretch;
      .data-sort {
        select {
          width: 100%;
        }
        margin-bottom: 16px;
      }
    }
    .data-buttons-wrapper {
      .data-buttons {
        overflow-y: scroll;
        white-space: pre-wrap;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 0px 26px 32px 26px;
        margin-right: 0px;
        width: 100%;
        height: calc(100vh - 200px);
        .data-button {
          height: 48px;
          width: 148px;
          margin: 8px 0px;
          padding: 12px 40px;
          .button-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            svg {
              font-size: 20px;
              position: absolute;
              left: 0px;
              top: 12px;
              width: 40px;
            }
            .data-button-text {
              display: flex;
              align-items: flex-start;
              .number-of-entries {
                font-size: 8px;
                line-height: 8px;
                letter-spacing: 1.5px;
              }
            }
          }
        }
        .data-button:first-of-type {
          margin: 16px 0px 12px 0px;
        }

        .data-button:last-of-type {
          margin: 12px 0px 16px 0px;
        }
        .data-button:nth-of-type(3) {
          margin-top: 24px;
          margin-left: 0px;
        }
        .data-button:nth-of-type(3):before {
          content: "";
          width: 100%;
          height: 1px;
          position: absolute;
          top: -18px;
          left: 0px;
        }
      }
    }
  }
}
