@import "../../index.scss";
.button-click-to-copy {
  position: relative;
  .button-info-transient {
    @extend .grey-button;
    @extend .text-14-cap;
    @extend .flex-by-row-centered;
    width: 132px;
    height: 32px;
    position: absolute;
    top: 48px;
    left: calc(50% - 66px);
  }
  .button-info-transient::after {
    background: var(--button-grey);
  }
  .button-info-transient.show {
    visibility: visible;
    opacity: 1;
  }
  .button-info-transient.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s ease-in-out;
  }
}
@media only screen and (min-width: $mobile-width) {
}
