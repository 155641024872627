@import "./index.scss";
$left-width: calc(70% - 43px);
$right-width: calc(30% - 21px);
.data-entity {
  @extend .card-8;
  @extend .flex-by-row-stretch;
  width: 100%;
  min-height: 168px;
  box-sizing: border-box;
  margin: 8px 0px;
  padding: 32px;
  position: relative;
  .entity-content-left {
    @extend .flex-by-column-left;
    justify-content: space-between;
    padding-right: 32px;
    overflow-x: hidden;
    width: 100%;
    .entity {
      margin-bottom: 20px;
      .type {
        @extend .text-12;
        text-transform: capitalize;
      }
      .name {
        @extend .title-16;
        margin-top: 5px;
      }
    }
    .parent {
      text-transform: capitalize;
      width: 100%;
      .type {
        @extend .text-10-upper;
      }
      .name {
        @extend .title-14;
        @extend .overflow-ellipsis;
      }
    }
  }
  .entity-content-right {
    @extend .flex-by-column-centered;
    width: 110px;
    justify-content: space-between;
    .children {
      @extend .flex-by-column-centered;
      .name {
        @extend .title-32;
      }
      .type {
        @extend .text-10-upper;
      }
    }
    .thumbnail-buttons {
      @extend .flex-by-row-centered;
      .thumbnail-button {
        @extend .flex-by-row-centered;
        border-radius: 8px;
        width: 40px;
        height: 40px;
        margin-right: 16px;
        svg {
          font-size: 16px;
        }
        span {
          display: none;
        }
      }
      .thumbnail-button:last-child {
        margin-right: 0px;
      }
      .thumbnail-button.edit-entity {
        box-shadow: 0 8px 32px 0 rgba(95, 92, 255, 0.24);
        background-color: var(--main-purple);
        color: var(--ghost-white);
      }
      .thumbnail-button.delete-entity {
        background-color: var(--ghost-white);
        color: var(--warning_red) !important;
        box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.24);
      }
      .thumbnail-button.delegate {
        background: var(--main-purple);
        width: auto !important;
        padding: 0px 16px;
        span {
          @extend .text-14-cap;
          color: white;
          display: inline-block;
        }
      }
      .thumbnail-button.delegate.disabled {
        opacity: 0.4;
      }
      .thumbnail-button.more-actions {
        margin: 0px;
        width: 40px;
        color: var(--eerie-black);
        box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.22);
        svg {
          font-size: 18px;
        }
      }
    }
    .thumbnail-buttons {
      margin-top: 16px;
    }
  }
}

.data-entity.empty {
  height: 0px;
  min-height: 0px;
  box-shadow: none;
  padding: 0px 32px;
  margin: 0px;
  background-color: transparent;
}

.data-entity.archived {
  .entity-content-left,
  .entity-content-right .children {
    opacity: 0.2;
  }
}
@media only screen and (min-width: $mobile-width) {
  .data-entity {
    width: 320px;
    flex-grow: 1;
    margin: 12px;
    .entity-content-left {
      flex-grow: 1;
    }
  }
  .data-entity.empty {
    margin: 1px 12px;
  }
}
