@import "./index.scss";
.eater-name {
  @extend .flex-by-row-left;
  width: 100%;
  height: 22px;
  svg {
    font-size: 20px;
    margin-right: 8px;
  }
  .eater-name-text {
    @extend .title-12;
    background-color: var(--light-green);
    color: white;
    padding: 0px 8px;
    border-radius: 8px;
    line-height: 22px;
    svg {
      font-size: 10px;
      margin-right: 5px !important;
    }
  }
}
