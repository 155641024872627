@import "./index.scss"; /* diet-entry is composed of diet-section */
.diet-entry {
  width: 100%;
  /* There are 3 diet-sections: diet-like-list/tags, diet-cuisines, diet-comments */
  .diet-section {
    width: 100%;
    margin-bottom: 24px;
  }
  .diet-section:last-child {
    margin-bottom: 0px;
  }

  .diet-section.diet-like-list {
    .diet-item {
      @extend .flex-by-row-between;
      @extend .text-14-cap;
      width: 100%;
      height: 32px;
      margin-top: 12px;
      margin-bottom: 0px;
      padding: 0px;
      opacity: 0.4;
      .diet-item-name {
        @extend .flex-by-row-centered;
        height: 32px;
        position: relative;
        svg {
          font-size: 20px;
        }
        span {
          position: absolute;
          left: 32px;
          width: 100px;
          text-align: left;
        }
      }
    }
    .diet-item.selected-like {
      color: var(--main-purple);
      opacity: 1;
    }
  }
  .diet-section.diet-like-tags {
    width: 100%;
    margin-bottom: 14px;
    .form-section-title {
      width: 100%;
    }
    .diet-likes {
      @extend .flex-by-row-left;
      flex-wrap: wrap;
    }
  }

  .diet-section.diet-cuisines {
    .select-button {
      width: 100%;
    }
  }
  .diet-section.diet-comments {
    .comments-wrapper {
      @extend .card-textarea;
      @extend .flex-by-row-centered;
      width: 100%;
      padding: 13px 8px;
      textarea {
        @extend .text-12;
        background-color: transparent;
        border: none;
        width: 100%;
        padding: 0px;
        margin: 0px;
      }
    }
  }
}
.step-form {
  .step-content-card {
    .form-section-title.eater-name {
      @extend .flex-by-row-left;
      text-transform: capitalize;
      svg {
        font-size: 24px;
        margin-right: 10px;
      }
      span {
        @extend .text-12;
        line-height: 22px;
        color: white;
        background-color: var(--main-purple);
        padding: 0px 8px;
        border-radius: 8px;
      }
      margin: 12px 0px 24px 0px;
    }
  }
}
