@import "../../../index.scss";

.dish-content {
  @extend .flex-by-column-stretch;
  @extend .text-12-cap;
  .dish-name {
    @extend .overflow-ellipsis;
    width: 100%;
    text-align: left;
    font-weight: bold;
  }
  .dish-description {
    width: 100%;
    font-weight: normal;
    .formatted-row {
      .formatted-item:first-child {
        overflow: hidden;
        flex-shrink: 1;
        .restaurant-name {
          @extend .overflow-ellipsis;
        }
      }
      .formatted-item:last-child {
        flex-shrink: 0;
      }
    }
  }
}
