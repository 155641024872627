@import "../../index.scss";
$verify-card-width: 400px;
$verify-card-height: 300px;

.verify-contact-wrapper {
  @extend .flex-by-column-centered;
  position: fixed;
  width: calc(100vw - #{$medium-margin} * 2);
  height: $verify-card-height;
  top: calc(50vh - #{$verify-card-height} / 2);
  left: $medium-margin;
  z-index: 40;
  .verify-contact-form {
    @extend .flex-by-column-centered;
    @extend .card-16;
    background-color: white;
    width: calc(100vw - 32px);
    max-width: 400px;
    margin: 0px 16px;
    padding: 56px 48px 48px 48px;
    .form-field {
      margin: 0px;
      input {
        @extend .text-20;
        @extend .card-input;
        @extend .flex-by-row-centered;
        padding-left: 20px;
        text-align: center;
        letter-spacing: 12px;
      }
    }
    button[type="submit"] {
      @extend .button-purple;
      @extend .text-14-upper;
      display: block;
      width: 100%;
      height: 48px;
      margin: 12px 0px 24px 0px;
    }
    button[type="submit"]:disabled {
      opacity: 0.25;
    }
    .alert {
      position: absolute;
      z-index: 41;
      bottom: 10px;
    }
    .resend {
      width: 100%;
      text-align: right;
      text-transform: capitalize;
    }
  }
}

@media only screen and (min-width: $mobile-width) {
  .verify-contact {
    left: calc(50vw - #{$verify-card-width} / 2);
    width: $verify-card-width;
  }
}
