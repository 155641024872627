@import "../../../index.scss";
.section-header {
  @extend .flex-by-row-between;
  width: 100%;
  margin-bottom: 16px;
  .expand-toggle {
    @extend .title-14;
    width: auto;
    color: var(--main-purple);
    svg {
      margin-left: 14px;
    }
  }
}
