@import "../../index.scss";
.phone-form {
  @extend .flex-by-column-centered;
  width: 255px;
  margin: 0px 0px 24px 0px;
  .form-field {
    width: 100%;
    margin-bottom: 8px;
    label {
      @extend .text-12-cap;
      display: block;
      margin-bottom: 4px;
    }
  }
  button {
    @extend .button-purple;
    @extend .text-14-upper;
    color: white;
    margin-top: 8px;
    width: 100%;
    height: 48px;
  }
}
