@import "./index.scss";
.action-list {
  @extend .flex-by-column;
  position: relative;
  z-index: 3;
}
.action-list.showing {
  z-index: 4;
}
.action-list ul {
  @extend .card-16;
  @extend .flex-by-column-centered;
  align-content: stretch;
  margin-top: 8px;
  position: absolute;
  top: 100%;
  left: -55px;
  list-style-type: none;
  padding: 12px 16px;
  align-items: flex-start;
  li {
    .action-item {
      @extend .flex-by-row-between;
      @extend .text-12-cap;
      width: 120px;
    }
  }
}
.action-list ul:before {
  content: "";
  background-color: white;
  box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.12);
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  left: calc(50% - 5px);
}
.action-list ul li {
  padding: 12px 0px;
  width: 100%;
}
.action-list ul li:first-child {
  padding-top: 0px;
}
.action-list ul li:last-child {
  padding-bottom: 0px;
}
.action-list ul a,
.action-list ul button {
  text-transform: capitalize !important;
  padding: 0px;
  width: 100%;
  justify-content: space-between;
  text-align: left;
  height: 24px;
  line-height: 24px;
  font-size: 12px !important;
  letter-spacing: 0.7px;
}

.action-list ul svg {
  margin-left: 15px;
  margin-right: 0px;
  font-size: 16px;
}
.action-items button {
  margin: 0px !important;
}
.action-items button svg {
  font-size: 14px !important;
  color: var(--eerie-black);
}
