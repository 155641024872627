@import "./index.scss";

.invoice-list {
  @extend .flex-by-column-top;
  width: 100%;
  padding: 0px;
  .invoice-card {
    @extend .card-16;
    @extend .text-14;
    width: 100%;
    margin-top: $large-margin;
    padding: 24px 16px;
    .invoice-card-section {
      width: 100%;
      border-bottom: 1px dotted var(--eerie-grey-25);
      padding: 16px 0px 10px 0px;
      .eater-name {
        margin-bottom: 16px;
        svg {
          margin-right: 10px;
          margin-left: 2px;
        }
      }
      .invoice-row {
        @extend .flex-by-row-between;
        width: 100%;
        height: 24px;
        margin-bottom: 4px;
        label {
          text-transform: capitalize;
        }
        .invoice-info {
          font-weight: 600;
        }
      }
      .invoice-row:last-child {
        margin-bottom: 0px;
      }
      .invoice-row.invoice-row-short {
        @extend .flex-by-row-left;
        margin-bottom: 16px;
        label.icon {
          @extend .flex-by-row-centered;
          height: 24px;
          width: 24px;
          margin-right: 10px;
          svg {
            position: relative;
            font-size: 22px;
          }
          svg.fa-calendar-day {
            left: -2px;
          }
          svg.fa-truck {
            font-size: 20px;
          }
          svg.fa-user-circle {
            font-size: 24px;
          }
          svg.fa-utensils {
            font-size: 21px;
          }
        }
      }
      .invoice-row.invoice-row-short:last-of-type {
        margin-bottom: 0px;
      }

      .invoice-row.invoice-row-short.goods {
        @extend .flex-by-row-top-left;
        height: auto;
      }
    }

    .invoice-card-section:first-of-type {
      padding-top: 0px;
    }
    .invoice-card-section:last-of-type {
      border-bottom: none;
      padding-bottom: 0px;
    }
  }
  .invoice-card:first-of-type {
    margin-top: 0px;
  }
  .no-invoice {
    @extend .text-14;
    @extend .flex-by-column-centered;
    height: 300px;
  }
}
.invoice-list.has-content {
  min-height: 100vh;
}
//750px or above
