@import "./index.scss";
.step-form.schedules {
  .step-form-header {
    width: 100%;
    @extend .flex-by-column-centered;
  }
}

.schedule-day {
  font-size: 16px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 36px;
    width: 36px;
  }

  .checkmark {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: solid 1px rgba(28, 29, 33, 0.25);
    color: rgb(198, 198, 199);
    font-size: 13px;
    font-weight: bold;
    line-height: 1.33;
  }

  input:checked ~ .checkmark {
    border: solid 1px rgba(28, 29, 33, 0.25);
    background-color: var(--main_purple);
    color: var(--ghost_white);
  }
  input:checked ~ .checkmark .text {
    opacity: 0.25;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: var(--ghost_white);
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    width: 36px;
    height: 36px;
    border: solid 1px rgba(28, 29, 33, 0.25);
  }

  .checkmark .text {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.4px;
    opacity: 0.25;
    color: var(--eerie_black);
  }
}
