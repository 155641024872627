@import "../../../index.scss";
.select-meal-type {
  @extend .flex-by-row-centered;
  margin: 0px 0px 32px 0px;
  button {
    width: calc(50% - 8px);
    max-width: 160px;
    height: 40px;
    border-radius: 8px;
    @extend .text-14-button;
    @extend .flex-by-row-centered;
  }
  button:last-child {
    margin-left: 16px;
  }
}
@media only screen and (min-width: $mobile-width) {
  .select-meal-type {
    justify-content: flex-start;
  }
}
